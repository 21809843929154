<template>
  <div class="media-wrapper">
    <div class="content">
      <!-- <h2 class="title">{{ formObj.articleTitle }}</h2> -->
      <div class="text">
        <p v-html="formObj.articleContent"></p>
      </div>
    </div>
    <div class="star">
      <div v-if="formObj.isCollection == 1" @click="cancelCollectionArticle">
        <van-icon name="star" color="#F79C42" />
        <span>取消收藏</span>
      </div>
      <div v-else @click="collectionArticle">
        <van-icon name="star-o" />
        <span>收藏本文</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Dialog, Notify, Toast } from "vant";
export default {
  name: "mediaDetail",
  components: {
    [Icon.name]: Icon,
    [Dialog.name]: Dialog,
    [Notify.name]: Notify,
  },
  data() {
    return {
      formObj: {},
    };
  },
  mounted() {
    this.queryArticleId();
  },
  methods: {
    queryArticleId() {
      this.$http
        .request("queryArticleId", {
          id: this.$route.query.id,
          patientId: this.$cache.local.getItem("patientId"),
        })
        .then((res) => {
          this.formObj = res;
        })
        .catch((err) => {});
    },
    collectionArticle() {
      this.$http
        .request("collectionArticle", {
          id: this.$cache.local.getItem("patientId"),
          articleId: this.$route.query.id,
        })
        .then((res) => {
          // Notify({ type: "success", message: "收藏成功" });
          Toast.success("收藏成功");
          this.queryArticleId();
        })
        .catch((err) => {});
    },
    cancelCollectionArticle() {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确认取消收藏此科普文章吗？",
        confirmButtonColor: "#1989fa",
      })
        .then(() => {
          this.$http
            .request("cancelCollectionArticle", {
              id: this.$cache.local.getItem("patientId"),
              articleId: this.$route.query.id,
            })
            .then((res) => {
              this.queryArticleId();
            })
            .catch((err) => {});
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.media-wrapper {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  .content {
    padding: 15px;
    box-sizing: border-box;
    height: calc(100vh - 70px);
    border-bottom: 1px solid #ccc;
    flex-shrink: 0;
    .title {
      // text-align: center;
      // line-height: 50px;
      margin: 10px 0;
      font-size: 22px;
      color: #333;
      word-break: break-all;
    }
    .text {
      font-size: 16px;
      color: #333;
      line-height: 30px;
      word-break: break-all;
      height: 99%;
      overflow: auto;
    }
  }

  .star {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    height: 70px;
    line-height: 70px;
    font-size: 15px;
    .van-icon {
      font-size: 20px;
      margin: 0 5px;
    }
  }
}
</style>