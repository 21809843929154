<template>
  <div class="doc-wrapper">
    <div class="top-wrapper">
      <div class="top-search">
        <van-search
          v-model="searchInput"
          show-action
          placeholder="搜索医院"
          @search="onClickButton"
          @clear="onClear"
        >
          <template #action>
            <div @click="onClickButton">搜索</div>
          </template>
        </van-search>
      </div>
      <div class="select-box">
        <van-field
          v-model="fieldValueArea"
          is-link
          readonly
          placeholder="选择区域"
          @click="showPicker = true"
        />
      </div>
    </div>

    <div class="doctor-list" @click="handleDoctor">
      <div v-if="allList.length != 0">
        <div
          v-for="itemFat in allList"
          :key="itemFat.id"
          class=""
          @click="toDetail(itemFat)"
        >
          <div class="hospital-box">
            <div class="hos-lft">
              <img
                v-if="itemFat.organizationHeadImg"
                class="img"
                :src="itemFat.organizationHeadImg"
                alt=""
              />
              <img v-else class="img" src="../assets/img/ar3.png" alt="" />
            </div>
            <div class="hos-rgt">
              <p class="hos-name">{{ itemFat.organizationName }}</p>
              <div class="hos-tag">
                <p>
                  <span v-if="itemFat.organizationLevel == 0">四级甲等</span>
                  <span v-if="itemFat.organizationLevel == 1">四级乙等</span>
                  <span v-if="itemFat.organizationLevel == 2">三级</span>
                  <span v-if="itemFat.organizationLevel == 3">三级甲等</span>
                  <span v-if="itemFat.organizationLevel == 4">三级乙等</span>
                  <span v-if="itemFat.organizationLevel == 5">三级丙等</span>
                  <span v-if="itemFat.organizationLevel == 6">二级</span>
                  <span v-if="itemFat.organizationLevel == 7">二级甲等</span>
                  <span v-if="itemFat.organizationLevel == 8">二级乙等</span>
                  <span v-if="itemFat.organizationLevel == 9">二级丙等</span>
                  <span v-if="itemFat.organizationLevel == 10">一级</span>
                  <span v-if="itemFat.organizationLevel == 11">一级甲等</span>
                  <span v-if="itemFat.organizationLevel == 12">一级乙等</span>
                  <span v-if="itemFat.organizationLevel == 13">一级丙等</span>
                </p>
                <p>
                  <span v-if="itemFat.organizationType == 0">综合性医院</span>
                  <span v-if="itemFat.organizationType == 1">专科医院</span>
                </p>
                <p>
                  <span v-if="itemFat.organizationAttribute == 0"
                    >公立医院</span
                  >
                  <span v-if="itemFat.organizationAttribute == 1"
                    >私立医院</span
                  >
                  <span v-if="itemFat.organizationAttribute == 2">其他</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="empty-text">暂无专家推荐</div>
    </div>
    <div class="bot-wrapper">
      <div class="bot-msg">
        <span>
          <img
            src="../assets/img/doc1.png"
            alt=""
            style="width: 30px; margin-right: 10px"
          />
          不知道选择哪个科室？
        </span>
        <span class="zixun" @click="handleSave">
          在线咨询
          <img src="../assets/img/lft2.png" alt="" style="width: 20px" />
        </span>
      </div>
    </div>
  </div>
  <van-popup
    v-model:show="showPicker"
    round
    position="bottom"
    :lazy-render="false"
  >
    <van-picker
      ref="areaPicker"
      :columns="columnsArea"
      :columns-field-names="{ text: 'name', value: 'id' }"
      @cancel="showPicker = false"
      @confirm="onConfirmArea"
    />
  </van-popup>
</template>
    
    <script>
import {
  Picker,
  Toast,
  Field,
  Popup,
  Cell,
  Calendar,
  Button,
  Icon,
  Tabs,
  Tab,
  Search,
  Dialog,
  Image,
  Notify,
} from "vant";

export default {
  name: "hospitalList",
  components: {
    [Picker.name]: Picker,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [Calendar.name]: Calendar,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Search.name]: Search,
    [Dialog.name]: Dialog,
    [Image.name]: Image,
    [Notify.name]: Notify,
  },
  data() {
    return {
      searchInput: "",
      ListActive: "0",
      showPicker: false,
      columnsArea: [],
      fieldValueArea: "",
      allList: [],
    };
  },
  created() {},
  mounted() {
    if (this.$cache.session.getItem("searchInputHos")) {
      this.searchInput = this.$cache.session.getItem("searchInputHos");
    }
    this.fieldValueArea = this.$route.query.locationCity;
    this.isAreType = this.$route.query.isAreType;
    this.queryAllArea();
    this.queryAllOrganization(this.fieldValueArea);
  },
  methods: {
    // 搜索
    onClickButton() {
      this.$cache.session.setItem("searchInputHos", this.searchInput);
      this.queryAllOrganization();
    },
    // 清除
    onClear() {
      this.$cache.session.removeItem("searchInputHos");
      this.queryAllOrganization();
    },
    // 机构
    queryAllOrganization(val) {
      this.$http
        .request("queryAreaOrganization", {
          addrName: val ? val : this.fieldValueArea,
          searchInput: this.searchInput,
        })
        .then((res) => {
          // 后台返回字段有disabled（是否启用服务），关键字，会把picker选项禁用
          res.forEach((item) => {
            delete item.disabled;
          });

          this.allList = res;
        })
        .catch((err) => {});
    },
    // 区域
    queryAllArea() {
      this.$http
        .request("queryMapsQuery", {})
        .then((res) => {
          // 除直辖市外，添加全省选项-等后台接口改
          res.forEach((item) => {
            // 北京，天津，上海，重庆
            let arr = [110000, 120000, 310000, 500000];
            if (arr.indexOf(item.code) == -1) {
              item.addrMaps.unshift({
                name: "全省",
                fatName: item.name,
                fatCode: item.code,
                fatId: item.id,
              });
            }
          });
          // console.error(res, "地区地区地区");
          // 选择框默认值
          res.forEach((item, index) => {
            item.children = item.addrMaps;
            item.children.forEach((city, idx) => {
              if (this.fieldValueArea == city.name) {
                setTimeout(() => {
                  this.$refs.areaPicker.setIndexes([index, idx]);
                }, 0);
              }
            });
          });

          this.columnsArea = res;
        })
        .catch((err) => {});
    },

    // 区域弹窗确认
    onConfirmArea(selectedOptions) {
      // console.log(selectedOptions, "selectedOptions222222");
      this.showPicker = false;
      if (selectedOptions[1].fatName) {
        this.fieldValueArea = selectedOptions[1].fatName;
        this.queryAllOrganization(selectedOptions[1].fatName);
      } else {
        this.fieldValueArea = selectedOptions[1].name;
        this.queryAllOrganization(selectedOptions[1].name);
      }
      // console.log(this.fieldValueArea, " this.fieldValueArea22222");
    },
    // 详情
    toDetail(item) {
      // console.log(item, "itemitemitem");
      this.$cache.session.setItem("searchInputHos", this.searchInput);
      this.$cache.session.setItem("searchInput", "");
      this.$router.push(
        "/doctorPageArea?locationCity=" +
          this.fieldValueArea +
          "&isAreType=" +
          this.isAreType +
          "&hospitalIdVal=" +
          item.id +
          "&hospitalNameVal=" +
          item.organizationName
      );
    },
    convertDoctorProfessional(val) {
      if (val == 1) {
        return "主任医师";
      } else if (val == 2) {
        return "副主任医师";
      } else if (val == 3) {
        return "主治医师";
      } else if (val == 4) {
        return "医士";
      } else if (val == 5) {
        return "主任药师";
      } else if (val == 6) {
        return "副主任药师";
      } else if (val == 7) {
        return "主管药师";
      } else if (val == 8) {
        return "药士";
      } else if (val == 9) {
        return "主任护师";
      } else if (val == 10) {
        return "副主任护师";
      } else if (val == 11) {
        return "主管护师";
      } else if (val == 12) {
        return "护士";
      } else {
        return val;
      }
    },
    // 在线咨询
    handleSave() {
		this.$http.request("zxCount",{}).then(()=>{}).catch(()=>{})
      this.$router.push("/onConsult");
    },
  },
  beforeDestroy() {},
};
</script>
      
    
    <style lang="scss" scoped>
.doc-wrapper {
  position: relative;
  height: 100%;
  .top-wrapper {
    height: 100px;
    width: 100%;
    background-color: #fff;
    position: fixed;
    top: 0;
    border-bottom: 1px solid #eee;
    .top-search {
      height: 55px;
    }
    .select-box {
      display: flex;
    }
  }
  .bot-wrapper {
    width: 100%;
    background-color: #fff;
    height: 70px;
    line-height: 70px;
    position: fixed;
    bottom: 0;
    .bot-msg {
      width: calc(100% - 30px);
      background-color: #e8f3ff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px;
      padding: 0 15px;
      box-sizing: border-box;
      border-radius: 8px;
      height: 50px;
      line-height: 50px;
      font-size: 15px;
      position: fixed;
      bottom: 0;
      span {
        display: flex;
        align-items: center;
      }
      .zixun {
        color: #278eff;
      }
    }
  }
}

.doctor-list {
  height: calc(100% - 170px);
  overflow: auto;
  position: fixed;
  top: 100px;
  width: 100%;

  .hospital-box {
    border-bottom: 1px solid #eee;
    height: 90px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    .hos-lft {
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      .img {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
    }
    .hos-rgt {
      .hos-name {
        font-size: 18px;
        color: #272d3b;
        font-weight: bold;
        padding-top: 12px;
        padding-bottom: 8px;
      }
      .hos-tag {
        display: flex;
        > p {
          color: #c5802f;
          // border: 1px solid #fff;
          background-color: #fff5e6;
          margin-right: 5px;
          padding: 2px 5px;
          font-size: 12px;
          transform: scale(0.9);
        }
      }
    }
  }
  .doctor-item {
    display: flex;
    // justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 15px;
    box-sizing: border-box;
    width: 100%;

    .doctor-rgt {
      margin-left: 15px;
      width: calc(100% - 80px);
      .rgt-one {
        display: flex;
        justify-content: space-between;
        .name {
          font-size: 16px;
          color: #272d3b;
          font-weight: bold;
        }
        .text {
          font-size: 13px;
          margin-left: 10px;
          color: #272d3b;
        }
        .cancel-follow {
          color: #9da7b1;
        }
        .team-box {
          color: #278eff;
          background-color: #e8f3fe;
          font-size: 12px;
          padding: 2px 5px;
          border-radius: 2px;
          margin-left: 5px;
        }
      }

      .doctor-text {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 13px;
        color: #b8c0c7;
        margin: 10px 0;
      }
      .doctor-tag {
        background-color: #fff5e6;
        color: #f79c42;
        font-size: 12px;
        border-radius: 2px;
        margin-right: 10px;
        padding: 0 5px;
      }
    }
  }
  .empty-text {
    color: #9da7b1;
    font-size: 16px;
    text-align: center;
    margin-top: 30px;
  }
}
</style>