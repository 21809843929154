<template>
  <!-- <div class="title-top">
    <van-icon name="cross" @click="$router.go(-1)" />
    <span>个人资料</span>
    <van-icon name="weapp-nav" />
  </div> -->
  <div class="my-wrapper">
    <div class="top-box">
      <!-- <van-image round width="1rem" height="1rem" :src="userInfo.avatar" /> -->
      <div class="top-rgt">
        <p>
          <span class="name">{{ userInfo.name }}</span>
          <span>{{ userInfo.work }}</span>
        </p>
        <p>{{ userInfo.hospital }}</p>
      </div>

      <div></div>
    </div>
    <div class="img-title">
      <div>
        <img class="img" src="../../assets/img/icon/ti.png" alt="" />
        <h3>基本信息</h3>
      </div>
      <div></div>
    </div>
    <div class="cell-box">
      <van-cell title="头像" is-link>
        <!-- <van-image round width=".5rem" height=".5rem" :src="basicInfo.avatar" /> -->
        <van-uploader :after-read="afterCard" accept="image/*">
          <!-- <img class="arrart" :src="basicInfo.avatar" /> -->
          <van-image
            round
            width=".5rem"
            height=".5rem"
            fit="contain"
            :src="basicInfo.avatar"
          />
          <!-- <van-tag type="danger" class="vip" size="medium">VIP</van-tag> -->
        </van-uploader>
      </van-cell>
      <van-cell
        title="手机号"
        is-link
        :value="basicInfo.phone"
        :to="{ path: '/fileEdit', query: { title: '修改手机号', textType: 2 } }"
      />
      <van-cell
        title="邮箱"
        is-link
        :value="basicInfo.email"
        :to="{ path: '/fileEdit', query: { title: '修改邮箱', textType: 2 } }"
      />
      <van-cell
        title="微信"
        is-link
        :value="basicInfo.weChat"
        :to="{ path: '/fileEdit', query: { title: '修改微信', textType: 2 } }"
      />
    </div>
    <div class="img-title">
      <div>
        <img class="img" src="../../assets/img/icon/ti.png" alt="" />
        <h3>个人标签</h3>
      </div>
      <div>
        <van-icon
          name="edit"
          v-if="tagsList.length != 0"
          @click="handleEdit('edit', '个人标签', 3)"
        />
        <van-icon
          name="add-o"
          v-else
          @click="handleEdit('add', '个人标签', 3)"
        />
      </div>
    </div>
    <div class="tag-box">
      <div v-for="item in tagsList" :key="item" class="tag-item">
        <span>{{ item }}</span>
        <van-icon name="cross" @click="deleteTag(item)" />
      </div>
    </div>
    <div class="img-title">
      <div>
        <img class="img" src="../../assets/img/icon/ti.png" alt="" />
        <h3>专业擅长</h3>
      </div>
      <div>
        <van-icon
          name="edit"
          v-if="zhaunye"
          @click="handleEdit('edit', '专业擅长', 4)"
        />
        <van-icon
          name="add-o"
          v-else
          @click="handleEdit('add', '专业擅长', 4)"
        />
      </div>
    </div>
    <div class="tag-box">
      <p>{{ zhaunye }}</p>
    </div>
    <div class="img-title">
      <div>
        <img class="img" src="../../assets/img/icon/ti.png" alt="" />
        <h3>个人简介</h3>
      </div>
      <div>
        <van-icon
          name="edit"
          v-if="jianjie"
          @click="handleEdit('edit', '个人简介', 4)"
        />
        <van-icon
          name="add-o"
          v-else
          @click="handleEdit('add', '个人简介', 4)"
        />
      </div>
    </div>
    <div class="tag-box">
      <p>{{ jianjie }}</p>
    </div>
  </div>
</template>
  
  <script>
import { Cell, Image, Icon, Uploader } from "vant";
export default {
  name: "profile",
  components: {
    [Cell.name]: Cell,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Uploader.name]: Uploader,
  },
  data() {
    return {
      userInfo: {
        name: "赵琦君",
        work: "主任医师 | 口腔科",
        hospital: "上海交通大学附属第九人民医院",
      },
      basicInfo: {
        avatar: "https://unpkg.com/@vant/assets/cat.jpeg",
        phone: "15534567890",
        email: "15534567890@163.com",
        weChat: "15534567890",
      },
      tagsList: ["龋齿", "牙齿矫正"],
      zhaunye: "",
      jianjie:
        "本科学历，中华口腔医学会种植专科委员会、湖北省全科及颌面创伤美容外科委员会委员。从事口腔工作二十余年，曾多次赴上级医院学习参加口腔研讨会...",
    };
  },
  methods: {
    handleEdit(type, val, textType) {
      this.$router.push("/fileEdit?title=" + val + "&textType=" + textType);
    },
    deleteTag(item) {
      console.log(item, "删除标签");
      this.tagsList = this.tagsList.filter((ele) => ele != item);
    },
    // 上传头像
    afterCard(file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", 1);
      console.log(file, "上传图片");
      // // 请求接口
      // uploadImg(formData).then((res) => {
      //   if (res.code == 200) {
          this.basicInfo.avatar = file.content;
      //   }
      // });
    },
  },
};
</script>
  
  <style scoped lang="scss">
.title-top {
  font-size: 18px;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.my-wrapper {
  .top-box {
    display: flex;
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
    background: -webkit-linear-gradient(top, #bfdeff, white);
    .top-rgt {
      p {
        line-height: 20px;
        .name {
          font-weight: bold;
          margin-right: 5px;
        }
      }
    }
  }
  .img-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    box-sizing: border-box;
    margin-top: 15px;
    > div {
      display: flex;
      align-items: center;
      .img {
        width: 5px;
        height: 13px;
        margin-right: 8px;
      }
      .van-icon {
        font-size: 16px;
      }
    }
  }
  .cell-box {
    background-color: #fff;
    border-radius: 8px;
  }
  .tag-box {
    background-color: #fafafb;
    border-radius: 8px;
    min-height: 100px;
    margin: 15px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    color: #5d6e7f;
    .tag-item {
      background-color: #fff;
      padding: 5px 10px;
      margin: 5px;
      border-radius: 5px;
      .van-icon {
        margin-left: 5px;
      }
    }
    p {
      line-height: 20px;
    }
  }
}
</style>