<template>
  <div class="view">
    <div class="title">当前登录账号已绑定手机号：</div>
    <div class="title">{{ $route.query.phone }}</div>
    <van-form @submit="submitUpdate">
      <van-cell-group inset>
        <van-field
          v-model="formObj.patientPhone"
          name="patientPhone"
          label="新绑定手机号"
          placeholder="请输入"
          :rules="[
            { required: true, message: '请输入手机号' },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号格式错误' },
          ]"
        />
        <van-field
          v-model="formObj.phoneCode"
          name="phoneCode"
          label="验证码"
          maxlength="6"
          placeholder="请输入"
          :rules="[{ required: true, message: '请输入验证码' }]"
        >
          <template #button>
            <van-button
              @click="getYzm"
              v-show="!isLoading.getYzm"
              plain
              size="small"
              type="primary"
              >获取验证码</van-button>
			  <van-button
			    v-show="isLoading.getYzm"
			    plain
			    size="small"
			    type="primary"
			    >{{dsLoadingText}}</van-button>
          </template>
        </van-field>
      </van-cell-group>

      <div style="margin: 1.8rem 16px 0.4rem">
        <van-button
          :loading="isLoading.submitUpdate"
          round
          block
          type="primary"
          native-type="submit"
          >确认修改</van-button
        >
        <van-button
          style="margin-top: 15px"
          round
          block
          @click="$router.go(-1)"
          >取消</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { Form, Field, CellGroup, Button, Toast } from "vant";
export default {
  name: "login",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
  },
  data() {
    return {
      formObj: {},
      isLoading: {},
      ds: null,
      dsLoadingText: "",
      pwreg: /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,16}$/,
    };
  },
  methods: {
    // 获取验证码
    getYzm() {
      if (this.$myVerify.isPhone(this.formObj.patientPhone)) {
        this.isLoading.getYzm = true;
        if (this.ds) {
          window.clearInterval(this.ds);
        }
        this.dsLoadingText = "请稍等...";
        this.$http
          .request("wxSendSmsCode", { patientPhone: this.formObj.patientPhone })
          .then(() => {
            let num = 60;
            this.ds = window.setInterval(() => {
              num--;
              if (num <= 0) {
                window.clearInterval(this.ds);
                this.dsLoadingText = "";
                this.isLoading.getYzm = false;
              }
              this.dsLoadingText = num + "秒";
            }, 1000);
          })
          .catch(() => {});
      } else {
        Toast({
          message: "手机号码错误",
        });
      }
    },
    // 确认修改
    submitUpdate(values) {
      this.isLoading.submitUpdate = true;
      console.log(values);
	  values.id=this.$cache.local.getItem("patientId")
	  // values.
      this.$http
        .request("updatePatientPhone", values)
        .then((res) => {
          this.isLoading.submitUpdate = false;
          Toast.success("修改成功");
		  this.$router.go(-1)
        })
        .catch((e) => {
          console.error(e);
          this.isLoading.submitUpdate = false;
        });
    },
  },
  beforeUnmount() {
    if (this.ds) {
      window.clearInterval(this.ds);
    }
  },
};
</script>

<style scoped lang="scss">
.view {
}
.title {
  margin: 30px var(--van-cell-horizontal-padding) 20px;
  font-size: 18px;
  font-weight: bold;
  color: #272d3b;
  line-height: 25px;
}
.mmts {
  font-size: 12px;
  padding: var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding);
  font-weight: 400;
  color: #9da7b1;
}
</style>
