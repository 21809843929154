<template>
  <div v-if="isShow.view" class="pages">
    <div class="logoView">
      <img src="../assets/img/huzBig.png" />
    </div>
    <div class="form">
      <h3 style="color: #00a8ff; text-align: center">会诊帮在线服务平台</h3>
      <!-- <div class="form-item">
	  <input placeholder="请输入姓名"  v-model="loginInfo.name" />
	</div>	 -->
      <div class="form-item">
        <!-- <img class="icon" src="../assets/img/icon/phone.png" /> -->
        <input
          placeholder="请输入手机号"
          type="tel"
          v-model="loginInfo.phone"
          :rules="[
            { required: true, message: '请填写手机号' },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号格式错误' },
          ]"
        />
      </div>
      <div class="form-item yzm">
        <!-- <img class="icon" src="../assets/img/icon/yzm.png" /> -->
        <input
          maxlength="6"
          v-model="loginInfo.code"
          placeholder="请输入验证码"
        />
        <span class="yzm-btn">
          <div v-if="isShow.codeType == 0" @click="getCode">获取验证码</div>
          <div v-else-if="isShow.codeType == 1">请稍等...</div>
          <div style="width: 90px" v-else-if="isShow.codeType == 2">
            {{ codeTime }}秒
          </div>
        </span>
      </div>
      <div style="text-align: center; margin-top: 30px">
        <van-button
          :disabled="isShow.toLogin"
          style="width: 80%; border-radius: 22px"
          @click="toLogin"
          type="primary"
          >绑定</van-button
        >
      </div>
      <div class="xys">
        <van-checkbox v-model="loginInfo.checked"
          >我已预约并同意
          <span @click.stop="toxy('axy')" class="xy">用户注册协议</span>、
          <span @click.stop="toxy('bxy')" class="xy">用户隐私协议</span>、
          <!-- <span @click.stop="toxy('cxy')" class="xy">知情同意书</span>、 -->
          <span @click.stop="toxy('dxy')" class="xy">会诊服务协议</span
          >的全部内容
        </van-checkbox>
      </div>
    </div>
    <van-popup
      v-model:show="isShow.axy"
      round
      :close-on-click-overlay="false"
      style="height: 80%"
    >
      <div class="xytknr">
        <protocola></protocola>
        <div style="text-align: center; margin: 20px">
          <van-button
            @click="isShow.axy = false"
            style="width: 80%; border-radius: 22px"
            type="primary"
            >同意</van-button
          >
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model:show="isShow.bxy"
      round
      :close-on-click-overlay="false"
      style="height: 80%"
    >
      <div class="xytknr">
        <protocolb></protocolb>
        <div style="text-align: center; margin: 20px">
          <van-button
            @click="isShow.bxy = false"
            style="width: 80%; border-radius: 22px"
            type="primary"
            >同意</van-button
          >
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model:show="isShow.cxy"
      round
      :close-on-click-overlay="false"
      style="height: 80%"
    >
      <div class="xytknr">
        <protocolc></protocolc>
        <div style="text-align: center; margin: 20px">
          <van-button
            @click="isShow.cxy = false"
            style="width: 80%; border-radius: 22px"
            type="primary"
            >同意</van-button
          >
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model:show="isShow.dxy"
      round
      :close-on-click-overlay="false"
      style="height: 80%"
    >
      <div class="xytknr">
        <protocold></protocold>
        <div style="text-align: center; margin: 20px">
          <van-button
            @click="isShow.dxy = false"
            style="width: 80%; border-radius: 22px"
            type="primary"
            >同意</van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Button, Toast, Dialog, Checkbox, Popup } from "vant";
import protocola from "../components/protocol/protocola.vue";
import protocolb from "../components/protocol/protocolb.vue";
import protocolc from "../components/protocol/protocolc.vue";
import protocold from "../components/protocol/protocold.vue";
export default {
  components: {
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Popup.name]: Popup,
    protocola,
    protocolb,
    protocolc,
    protocold,
  },
  name: "index",
  data() {
    return {
      loginInfo: {
        openid: "",
        name: "",
        phone: "",
        checked: false,
      },
      codeTime: 0,
      isShow: {
        codeType: 0, //0获取验证码 1请稍等 2还剩xx秒 --用于显示
        view: false,
        toLogin: false,
      },
      wxCode: "",
      ds: null,
      toPath: "",
      qObj: {},
      appid: "",
    };
  },
  props: {},
  watch: {},
  created() {
    // ?code=123456&state=%26undefined%3Dundefined&appid=wx78a9fa0ab376866b
    // ?code=011WKEFa1JCWiE036cGa1y8Af54WKEFT&state=%26undefined%3Dundefined&appid=wx78a9fa0ab376866b
    // this.wxCode = '091RrPGa1qtY8D0acOHa1RwJHz1RrPGf'
    let state = decodeURIComponent(this.$route.query.state);
    if (this.$route.query.appid) {
      this.appid = this.$route.query.appid;
    }
    if (this.$route.query.code) {
      this.wxCode = this.$route.query.code;
    }
    console.log(state, this.$route.query);
    try {
      state = state.split("&");
    } catch {}
    console.log(state);
    for (let i in state) {
      if (state[i].split("=")[0] == "routerUrl") {
        this.toPath = state[i].split("=")[1];
      } else if (state[i].split("=")[0] == "appid") {
        this.appid = state[i].split("=")[1];
      }else{
		  this.qObj[state[i].split("=")[0]] = state[i].split("=")[1];
	  }
      
    }
    console.log(this.qObj, this.toPath);
    this.getInfoByCode();
  },
  activated() {},
  deactivated() {},
  mounted() {},
  methods: {
    // 获取用户信息通过 微信code state回调地址//-目前只有这一种
    getInfoByCode() {
      // // 测试
      // this.loginInfo.openid="or3jW53gbPuy67qm0snS"
      // this.isShow.view=true;
      // return;
      // 测试结束
      this.isShow.view = false;
      let instance = Toast.loading({
        message: "请稍等...",
        duration: 0,
        forbidClick: true,
      });
      this.$http
        .request("getToken", { wxCode: this.wxCode, wxAppid: this.appid })
        .then((res) => {
          console.log(res);
          instance.clear();
          if (res.Authorization) {
            this.$cache.local.setItem("token", res.Authorization);
            this.$cache.local.setItem("patientId", res.patientId);
            this.$cache.local.setItem("companyId", res.organizationId);
            this.$cache.local.setItem(
              "organizationName",
              res.organizationName || ""
            );
            this.$cache.local.setItem("appid", this.appid);
            this.$cache.local.removeItem("addrId");
            this.$cache.local.removeItem("addrName");
            this.$myws.initMyWs(res.patientId);
            // openId
            // window.location.replace(window.location.href.split('pillboxie')[0]+'pillboxie/#/'+this.toPath)
			this.$http.request("loginCount",{}).then(()=>{}).catch(()=>{})
            this.$router.replace({
              path: this.toPath||"/",
              query: this.qObj,
            });
          } else {
            this.loginInfo.openid = res.openId;
            this.isShow.view = true;
            // 未绑定手机号-绑定
          }
        })
        .catch((err) => {
          console.log(err);
          instance.clear();
          Dialog.confirm({
            title: "微信授权失败",
            message: "是否再次进行授权?",
          })
            .then((res) => {
              let q = "?";
			  q+='appid='+this.appid;
			  q+="&routerUrl="+(this.toPath||'');
              for (let i in this.qObj) {
                q += "&" + i + "=" + this.qObj[i] || "";
              }
              window.location.href = "./loginPage.html" + q;
            })
            .catch(() => {});
        });
    },
    // 获取验证码
    getCode() {
      this.isShow.codeType = 1;
      if (this.$myVerify.isPhone(this.loginInfo.phone)) {
        this.$http
          .request("wxSendSmsCode", { patientPhone: this.loginInfo.phone })
          .then(() => {
            this.isShow.codeType = 2;
            window.clearInterval(this.ds);
            this.codeTime = 60;
            this.ds = window.setInterval(() => {
              this.codeTime--;
              if (this.codeTime == 0) {
                this.isShow.codeType = 0;
              }
            }, 1000);
          })
          .catch(() => {
            this.isShow.codeType = 0;
          });
      } else {
        this.isShow.codeType = 0;
        Toast({
          message: "手机号码错误",
        });
      }
    },
    // 去登陆--实际为绑定手机号-or 手机号登录
    toLogin() {
      if (!this.loginInfo.checked) {
        Toast("请同意并勾选协议");
        return;
      }
      if (!this.$myVerify.isPhone(this.loginInfo.phone)) {
        Toast({
          message: "手机号错误",
          duration: 2000,
        });
        return;
      } else if (!this.loginInfo.code) {
        Toast({
          message: "请填写验证码",
          duration: 2000,
        });
        return;
      }
      let instance = Toast({
        message: "请稍等...",
      });
      this.isShow.toLogin = true;
      this.$http
        .request("login", {
          patientPhone: this.loginInfo.phone,
          phoneCode: this.loginInfo.code,
          openId: this.loginInfo.openid,
          // patientName:this.loginInfo.name,
          wxAppid: this.appid,
          // wxType:"GZH"
        })
        .then((res) => {
          // 获取需跳转的页面
          this.isShow.toLogin = false;
          this.$cache.local.setItem("token", res.Authorization);
          this.$cache.local.setItem("patientId", res.patientId);
          this.$cache.local.setItem("companyId", res.organizationId);
          this.$cache.local.setItem(
            "organizationName",
            res.organizationName || ""
          );
          this.$cache.local.setItem("appid", this.appid);
          this.$cache.local.removeItem("addrId");
          this.$cache.local.removeItem("addrName");
          // 登录成功 commonProblem
          // 开启ws
          this.$myws.initMyWs(res.patientId);
          // window.location.replace(window.location.href.split('pillboxie')[0]+'pillboxie/#/'+this.toPath)
          this.$router.replace({
            path: this.toPath||'/',
            query: this.qObj,
          });
        })
        .catch((err) => {
          this.isShow.toLogin = false;
        });
    },
    toxy(u) {
      console.log("跳转协议页面", u);
      this.isShow[u] = true;
    },
  },
  computed: {},
  // deactivated() {},`
  beforeUnmount() {
    if (this.ds) {
      window.clearInterval(this.ds);
    }
  },
};
</script>

<style scoped="scoped" lang="scss">
.pages {
}
.mint-cell {
}
.logoView {
  height: 260px;
  background: linear-gradient(180deg, #278eff 0%, #ffffff 100%);
  text-align: center;
  overflow: hidden;
  img {
    margin-top: 60px;
    width: 4rem;
    height: 120px;
    object-fit: contain;
  }
}
.form {
  z-index: 100;
  // width: 5.6rem;
  padding: 30px 0.4rem;
  border-radius: 20px;
  position: relative;
  top: -60px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 -6px 6px #ccc;
  .form-item {
    height: 60px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    img.icon {
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }
    input {
      outline: 0;
      border: 0;
      height: 24px;
    }
  }
}
.yzm-btn {
  color: #278eff;
}
.xys {
  padding: 20px;
  font-size: 14px;
  .xy {
    color: #278eff;
  }
}
.xytknr {
  width: 80vw;
  box-sizing: border-box;
  padding: 20px;
}
</style>
