<template>
  <div class="pwd-wrapper">
    <h3>当前登录账号：{{ userInfo.userName }}</h3>
    <van-cell-group inset>
      <van-field
        v-model="tel"
        type="tel"
        label="手机号"
        placeholder="请输入"
        :rules="[
          { required: true, message: '请填写手机号' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号格式错误' },
        ]"
      />
      <van-field
        v-model="sms"
        center
        clearable
        label="验证码"
        placeholder="请输入"
      >
        <template #button>
          <van-button size="small" type="primary">发送验证码</van-button>
        </template>
      </van-field>
      <van-field
        v-model="password"
        type="password"
        label="密码"
        placeholder="请输入密码"
      />
      <van-field
        v-model="password"
        type="password"
        label="确认密码"
        placeholder="请重新输入密码"
      />
    </van-cell-group>
    <p class="tips">密码需为8-16位，必须包含：数字、大写字母、小写字母</p>
    <div class="btn-box">
      <van-button
        class="btn-submit"
        type="primary"
        size="large"
        round
        @click="handleSave"
        >确认修改</van-button
      >
      <van-button class="btn-cancel" size="large" round>取消</van-button>
    </div>
  </div>
</template>
  
  <script>
import { Field, CellGroup, Button, Dialog } from "vant";
export default {
  name: "updatePwd",
  components: {
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      userInfo: {
        userName: "AH123456",
        phone: "",
        code: "",
        password1: "",
        password2: "",
      },
    };
  },
  mounted() {},
  methods: {
    handleSave() {
      Dialog.alert({
        title: "温馨提示",
        message: "密码已修改成功",
        confirmButtonColor: "#1989fa",
        confirmButtonText: "好的",
      }).then(() => {
        // on close
      });
    },
  },
};
</script>
  
  <style  scoped lang="scss">
.pwd-wrapper {
  margin: 15px;
  .van-cell-group {
    margin: 20px 0;
  }
  .tips {
    font-size: 12px;
    color: #8e8e8e;
  }
  .btn-box {
    margin-top: 100px;
    .van-button {
      margin: 10px 0;
    }
    .btn-submit {
    }
    .btn-cancel {
      border: 2px solid #1989fa;
      color: #1989fa;
    }
  }
}
</style>