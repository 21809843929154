<template>
  <!-- <div class="title-top">
    <van-icon name="cross" @click="$router.go(-1)" />
    <span>{{ title }}</span>
    <van-icon name="weapp-nav" />
  </div> -->
  <div class="type-box">
    <div class="text-input" v-if="textType == 2">
      <van-cell-group inset>
        <van-field
          v-model="message"
          clearable
          :placeholder="'请填写' + title"
        />
      </van-cell-group>
    </div>
    <div class="text-input" v-if="textType == 3">
      <van-cell-group inset class="tags">
        <van-field v-model="message" clearable placeholder="请填写标签名称" />
        <span class="add-btn" @click="addTag">添加</span>
      </van-cell-group>
      <div class="tag-wrapper">
        <h4>已添加的标签（2/6）</h4>
        <div class="tag-box">
          <div v-for="item in tagsList" :key="item" class="tag-item">
            <span>{{ item }}</span>
            <van-icon name="cross" @click="deleteTag(item)" />
          </div>
        </div>
      </div>
    </div>
    <div class="text-area" v-if="textType == 4">
      <van-cell-group inset>
        <van-field
          v-model="message"
          rows="10"
          autosize
          clearable
          type="textarea"
          maxlength="500"
          :placeholder="'请填写' + title"
          show-word-limit
        />
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { Field, CellGroup, Icon, Button, Notify } from "vant";
export default {
  name: "fileEdit",
  components: {
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Notify.name]: Notify,
  },
  data() {
    return {
      title: "",
      message: "",
      textType: true,
      tagsList: ["龋齿", "牙齿矫正"],
    };
  },
  mounted() {
    this.title = this.$route.query.title;
    this.textType = this.$route.query.textType;
    console.log(this.textType, "textType");
  },
  methods: {
    addTag() {
      console.log(this.message, "添加");
      if (this.tagsList.indexOf(this.message) == -1) {
        this.tagsList.push(this.message);
        this.message = "";
      } else {
        Notify({ type: "warning", message: "您已经添加过此标签了" });
      }
    },
    deleteTag(item) {
      console.log(item, "删除标签");
      this.tagsList = this.tagsList.filter((ele) => ele != item);
    },
  },
};
</script>

<style  scoped lang="scss">
.title-top {
  font-size: 18px;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.type-box {
  margin-top: 15px;
  .text-input {
    .van-field {
      background-color: #f6f5f8;
    }
    .tag-wrapper {
      margin: 30px 20px;
      .tag-box {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        color: #5d6e7f;
        .tag-item {
          background-color: #f6f5f8;
          padding: 5px 10px;
          margin-top: 10px;
          margin-right: 10px;
          border-radius: 5px;
          .van-icon {
            margin-left: 5px;
          }
        }
      }
    }
    .tags {
      display: flex;
      .add-btn {
        width: 60px;
        background-color: #2592fa;
        color: #fff;
        font-size: 16px;
        text-align: center;
        line-height: 44px;
      }
    }
  }
  .text-area {
    .van-field {
      background-color: #f6f5f8;
    }
    .empty-box {
      color: #2592fa;
      font-size: 18px;
      text-align: right;
      margin-top: 20px;
    }
  }
}
</style>