<template>
  <div class="view">
    <van-search
      v-model="searchInput"
      show-action
      placeholder="搜索"
      @search="onClickButton"
    >
      <template #action>
        <div @click="onClickButton">搜索</div>
      </template>
    </van-search>
    <van-tabs
      class="tabs"
      title-active-color="#278EFF"
      v-model:active="ListActive"
      @change="activeOrderStatus"
    >
      <van-tab
        :name="item.id"
        :title="item.name"
        v-for="item in typeList"
        :key="item.id"
      >
        <div class="list">
          <div v-if="orderList.length == 0" class="listNull">
            <img src="../assets/img/empty.png" />
            <p>暂无订单数据</p>
          </div>
          <div v-else>
            <div
              v-for="item2 in orderList"
              :key="item2.id"
              class="list-item"
              @click="toDetail(item2)"
            >
              <div class="item-con">
                <p class="two">
                  <span>
                    <span>
                      <span
                        v-if="item2.serverType == '1'"
                        class="type-color type-color1"
                        >图文</span
                      >
                      <span
                        v-if="item2.serverType == '2'"
                        class="type-color type-color2"
                        >语音</span
                      >
                      <span
                        v-if="item2.serverType == '3'"
                        class="type-color type-color3"
                        >视频</span
                      >
                    </span>
                    <span class="name">{{ item2.doctorName }}</span>
                    <span class="text"
                      >{{
                        convertDoctorProfessional(item2.doctorProfessional)
                      }}
                      | {{ item2.departmentName }}</span
                    >
                  </span>
                  <span>
                    <!-- 订单状态 0 待支付 1已支付 ，2关闭，3已取消，4已完成 5进行中 -->
                    <span v-if="item2.orderStatus == '0'" class="status-color2"
                      >待支付
                      <van-count-down
                        :time="item2.timeDjs"
                        format="HH:mm:ss"
                        class="timer-color"
                      />
                    </span>
                    <span
                      v-else-if="item2.orderStatus == '1'"
                      class="status-color1"
                      >已支付</span
                    >
                    <span
                      v-else-if="item2.orderStatus == '2'"
                      class="status-color3"
                      >已关闭</span
                    >
                    <!-- <span
                    v-else-if="item2.orderStatus == '3'"
                    class="status-color3"
                    >已取消</span
                  > -->
                    <span
                      v-else-if="item2.orderStatus == '4'"
                      class="status-color1"
                      >已完成</span
                    >
                    <span
                      v-else-if="item2.orderStatus == '5'"
                      class="status-color1"
                      >进行中</span
                    >
                  </span>
                </p>
                <p class="one">
                  <span>{{ item2.organizationName }}</span>
                </p>
				<!-- <p class="one">
					<span>就诊人：XXX</span>
				</p> -->
                <p class="two">
                  <span class="time">{{ item2.createTime }}</span>
                  <span class="text"
                    >实付款 ￥{{ getServerPrice(item2.serverPrice) }}</span
                  >
                </p>
                <p class="btn-box">
                  <!-- <van-button
                  plain
                  round
                  color="#9DA7B1"
                  size="small"
                  @click.stop="handleInvoice"
                  >申请开票</van-button
                > -->
                  <van-button
                    plain
                    round
                    color="#9DA7B1"
                    size="small"
                    v-if="item2.orderStatus == '3' || item2.orderStatus == '4'"
                    @click.stop="handleDelete(item2)"
                    >删除订单</van-button
                  >
                  <van-button
                    plain
                    round
                    color="#9DA7B1"
                    size="small"
                    v-if="item2.orderStatus == '0'"
                    @click.stop="handleCancel(item2)"
                    >取消订单</van-button
                  >
                  <van-button
                    plain
                    round
                    type="primary"
                    size="small"
                    v-if="item2.orderStatus == '0'"
                    @click.stop="yzf(item2)"
                    >立即支付</van-button
                  >
                  <van-button
                    plain
                    round
                    type="primary"
                    size="small"
                    v-if="item2.orderStatus == '1'"
                    @click.stop="toZxRoom(item2)"
                    >导医咨询</van-button
                  >
				  <van-button
				    plain
				    round
				    type="primary"
				    size="small"
				    v-if="item2.orderStatus == '5'"
				    @click.stop="tohzRoom(item2)"
				    >接入对话</van-button
				  >
                  <van-button
                    plain
                    round
                    type="primary"
                    size="small"
                    v-if="item2.orderStatus == '4'"
                    @click.stop="handleConsult(item2)"
                    >再次咨询</van-button
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
  
  <script>
import {
  Picker,
  Toast,
  Field,
  Popup,
  Cell,
  Calendar,
  Button,
  Icon,
  Tabs,
  Tab,
  Search,
  Dialog,
  CountDown,
} from "vant";

export default {
  name: "orderList",
  components: {
    [Picker.name]: Picker,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [Calendar.name]: Calendar,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Search.name]: Search,
    [Dialog.name]: Dialog,
    [CountDown.name]: CountDown,
  },
  data() {
    return {
      searchInput: "",
      ListActive: 0,
      // 订单状态 0 待支付 1已支付 ，2关闭，3已取消，4已完成 5进行中
      typeList: [
        {
          id: 0,
          name: "全部",
        },
        { id: 1, name: "待支付" },
        { id: 6, name: "进行中" },
        { id: 5, name: "已完成" },
        // { id: 4, name: "已取消" },
        { id: 2, name: "已支付" },
        { id: 3, name: "已关闭" },
      ],
      orderList: [],
      isLoading: {
        zf: false,
      },
      weChatParameter: {},
      zfItem: {},
	  isShow:{},
	  iframeSrc:"",
    };
  },
  created() {},
  mounted() {
    this.ListActive = parseInt(this.$route.query.isOrderStatus);
    this.getOrderConsultList(this.ListActive);
  },
  methods: {
    // 搜索
    onClickButton() {
      this.getOrderConsultList();
    },
    // 获取列表
    getOrderConsultList(val) {
      this.$http
        .request("getOrderConsultList", {
          patientId: this.$cache.local.getItem("patientId"),
          // orderStatus: val ? val - 1 : "",
          orderStatus: this.ListActive ? this.ListActive - 1 : "",
          searchInput: this.searchInput,
        })
        .then((res) => {
          res.forEach((item) => {
            // 显示第一个科室
            item.departmentName = item.departmentName.split(",")[0];
            let sec = this.formatSeconds(item.createTime);
            // let sec = this.formatSeconds("2022-12-06 17:00:00");
            let secNum = (3600000*24) - sec;
            if (secNum > 0) {
              item.timeDjs = secNum;
            } else {
              item.timeDjs = 0;
              // item.orderStatus = 2;
            }
          });
          this.orderList = res;
        })
        .catch((err) => {});
    },
    // 待支付倒计时
    formatSeconds(time) {
      let timer = new Date().getTime() - new Date(time).getTime();
      return parseInt(timer);
    },
    // 切换tads
    activeOrderStatus(val) {
      this.getOrderConsultList(val);
    },
    // 详情
    toDetail(item) {
      this.$router.push("/orderDetail?orderId=" + item.id);
    },
    // 删除订单
    handleDelete(item2) {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确定删除当前订单吗？",
        confirmButtonColor: "#1989fa",
      })
        .then(() => {
          this.$http
            .request("delAssistantOrder", {
              id: item2.id,
            })
            .then((res) => {
              Toast.success("删除成功");
              this.getOrderConsultList();
            })
            .catch((err) => {});
        })
        .catch(() => {});
    },
    // 取消订单
    handleCancel(item2) {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确定取消当前订单吗？",
        confirmButtonColor: "#1989fa",
      })
        .then(() => {
          this.$http
            .request("cancelOrder", {
              id: item2.id,
            })
            .then((res) => {
              Toast.success("取消成功");
              this.getOrderConsultList();
            })
            .catch((err) => {});
        })
        .catch(() => {});
    },
    handleInvoice() {
      return false;
    },
	// 咨询聊天室
	toZxRoom(item){
      this.$router.push({
        path: "/yizhuChatRoom",
		query:{
			seeDoctorId:item.seeDoctorId,
			id:item.roomId,
		},
      });
    },
	// 跳转会诊聊天室
	tohzRoom(item){
		this.$router.push({
			path:"/chatRoom",
			query:{
			seeDoctorId:item.seeDoctorId,
			roomId:item.huiRoomId,
		}
		})
	},
    handleConsult(item) {
		this.$http.request("zxCount",{}).then(()=>{}).catch(()=>{})
      this.$router.push({
        path: "/onConsult",
		query:{
			docId:item.doctorId,
			seeDoctorId:item.seeDoctorId,
		},
      });
    },
    convertDoctorProfessional(val) {
      if (val == 1) {
        return "主任医师";
      } else if (val == 2) {
        return "副主任医师";
      } else if (val == 3) {
        return "主治医师";
      } else if (val == 4) {
        return "医士";
      } else if (val == 5) {
        return "主任药师";
      } else if (val == 6) {
        return "副主任药师";
      } else if (val == 7) {
        return "主管药师";
      } else if (val == 8) {
        return "药士";
      } else if (val == 9) {
        return "主任护师";
      } else if (val == 10) {
        return "副主任护师";
      } else if (val == 11) {
        return "主管护师";
      } else if (val == 12) {
        return "护士";
      } else {
        return val;
      }
    },
    // 价格转换
    getServerPrice(n) {
      if (!n) {
        return n;
      }
      n = n / 100;
      return Number(n.toFixed(2));
    },
    // 点击支付
    yzf(item) {
      if (this.isLoading.zf) {
        Toast("正在支付中...");
        return;
      }
      this.isLoading.zf = true;

      this.weChatParameter = {};
      this.zfItem = item;
      this.$http
        .request("getOrderJSAPIPrepay", { id: item.id })
        .then((res) => {
          if (res && res.appId) {
            this.weChatParameter = res;
            this.weixinPay();
          } else {
            
            this.iframeSrc =window.location.origin+"/"+
              process.env.BASE_URL +
              "hzbLoginPage.html?uid=" +
              this.$cache.local.getItem("patientId")
			  +"&returnUrl="+window.encodeURIComponent(window.location.href);
            window.location.replace(this.iframeSrc);
          }
        })
        .catch(() => {
          this.isLoading.zf = false;
        });
    },

    //解决微信内置对象报错
    weixinPay() {
      let instance = Toast("请稍等...");
      var that = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            that.onBridgeReady(instance),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent(
            "WeixinJSBridgeReady",
            that.onBridgeReady(instance)
          );
          document.attachEvent(
            "onWeixinJSBridgeReady",
            that.onBridgeReady(instance)
          );
        }
      } else {
        that.onBridgeReady(instance);
      }
    },
    //微信内置浏览器类，weChatParameter对象中的参数是3.步骤代码中从后端获取的数据
    onBridgeReady(instance) {
      var that = this;
      console.error("支付前");
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: that.weChatParameter.appId, //公众号名称，由商户传入
          timeStamp: that.weChatParameter.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: that.weChatParameter.noticeStr, //随机串
          package: that.weChatParameter.prepayId,
          signType: "RSA", //微信签名方式：RSA
          paySign: that.weChatParameter.sign, //微信签名
          // jsApiList: ['chooseWXPay']
        },
        function (res) {
          console.error(res);
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            Toast.success("支付成功");
            this.zfItem.orderStatus = "1";
          } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
            //取消支付的操作
            instance.close();
            that.isLoading.zf = false;
          } else {
            //支付失败的操作
            instance.close();
            Toast("支付失败");
            that.isLoading.zf = false;
          }
        }
      );
    },
  },
  beforeDestroy() {},
};
</script>
  
  <style scoped lang="scss">
.view {
  height: 100%;
  .tabs::v-deep(.van-tabs__line) {
    background-color: #278eff;
  }
  .tabs::v-deep(.van-tabs__content) {
    height: calc(100% - 44px);
    overflow: auto;
  }
  .tabs {
    height: calc(100% - 96px);
    background: #ffffff;
  }
  .list {
    // height: 200vh;
    padding: 10px 0;
    .listNull {
      width: 4.26rem;
      margin: calc(50vh - 280px) auto 0;
      img {
        height: 2.74rem;
      }
      p {
        text-align: center;
        opacity: 0.5;
      }
    }
    .list-item {
      width: 7.1rem;
      margin: 10px auto 0;
      height: 190px;
      background: #ffffff;
      color: #272d3b;
      box-shadow: 0px 2px 6px 0px rgba(225, 225, 225, 0.5);
      border-radius: 8px;
      padding: 10px;
      box-sizing: border-box;
      .item-img {
        width: 90px;
        height: 90px;
        border-radius: 8px;
        overflow: hidden;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .item-con {
        margin-left: 15px;
        font-size: 14px;
        // .title {
        //   color: #333;
        //   font-size: 16px;
        //   line-height: 30px;
        // }
        // .text {
        //   font-size: 14px;
        //   color: #999;
        //   line-height: 22px;
        //   text-overflow: ellipsis;
        //   overflow: hidden;
        //   display: -webkit-box;
        //   -webkit-line-clamp: 2; //元素几行显示
        //   -webkit-box-orient: vertical;
        // }

        .two {
          display: flex;
          justify-content: space-between;

          .type-color {
            padding: 2px 5px;
            border-radius: 3px;
          }
          .type-color1 {
            background-color: #dfeeff;
            color: #278eff;
          }
          .type-color2 {
            background-color: #dff6f2;
            color: #2ac2a8;
          }
          .type-color3 {
            background-color: #fdf2e7;
            color: #f79c42;
          }
          .name {
            font-size: 16px;
            font-weight: bold;
            margin: 0 10px;
            color: #272d3b;
          }
          .text {
            color: #272d3b;
          }
          .status-color1 {
            color: #2ac2a8;
          }
          .status-color2 {
            color: #f79c42;
            display: flex;
            .timer-color {
              color: #f79c42;
              margin-left: 5px;
            }
          }
          .status-color3 {
            color: #5d6e7f;
          }

          .time {
            color: #5d6e7f;
            font-size: 13px;
          }
        }
        .one {
          color: #5d6e7f;
          margin: 8px 0;
        }
        .btn-box {
          text-align: right;
          margin-top: 15px;
          .van-button {
            margin-left: 10px;
            padding: 0 15px;
          }
        }
      }
    }
  }
}
</style>
  