<template>
  <div :style="{ height: docmHeight + 'px' }" class="pat-wrapper">
    <p class="tips">您需要先添加就诊人，才可进行预约：</p>
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field name="isSelf" label="是否本人">
          <template #input>
            <van-radio-group v-model="patForm.isSelf" direction="horizontal">
              <van-radio :name="1">本人</van-radio>
              <van-radio :name="0">非本人</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="patForm.name"
          name="name"
          label="名字"
          placeholder="名字"
          :rules="[{ required: true, message: '请填写名字' }]"
        />
        <van-field
          v-model="patForm.cardTypeName"
          is-link
          readonly
          name="cardType"
          label="证件类型"
          placeholder="请选择证件类型"
          @click="showPicker = true"
        />
        <van-popup v-model:show="showPicker" position="bottom">
          <van-picker
            :columns="columns"
            value-key="label"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <van-field
          v-model="patForm.cardNum"
          name="cardNum"
          label="证件号码"
          placeholder="必填"
          @input="getAge"
          :rules="[
            { required: true, message: '请填写证件号码' },
            { validator, message: '证件号码格式错误' },
          ]"
        />
        <van-field
          v-model="patForm.age"
          name="age"
          label="年龄"
          placeholder="必填"
          type="number"
          :rules="[{ required: true, message: '请填写年龄' }]"
        />
        <!-- <van-field
          v-model="patForm.sex"
          name="sex"
          label="性别"
          placeholder="必填"
          :rules="[{ required: true, message: '请填写性别' }]"
        /> -->
        <van-field name="sex" label="性别">
          <template #input>
            <van-radio-group v-model="patForm.sex" direction="horizontal">
              <van-radio :name="1">男</van-radio>
              <van-radio :name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="patForm.phone"
          name="phone"
          label="手机号码"
          placeholder="请输入手机号码"
          :rules="[
            { required: true, message: '请填写手机号码' },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误' },
          ]"
        />
      </van-cell-group>
      <div class="btn-box">
        <van-button round block type="primary" native-type="submit">
          确认添加
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {
  Picker,
  Toast,
  Field,
  Popup,
  Cell,
  Calendar,
  Button,
  Icon,
  Tabs,
  Tab,
  Search,
  Radio,
  RadioGroup,
  CellGroup,
  Form,
} from "vant";
export default {
  name: "patientAdd",
  components: {
    [Picker.name]: Picker,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [Calendar.name]: Calendar,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Search.name]: Search,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [CellGroup.name]: CellGroup,
    [Form.name]: Form,
  },
  data() {
    return {
      showPicker: false,
      columns: [
        {
          value: 0,
          label: "身份证",
        },
        {
          value: 1,
          label: "护照",
        },
        {
          value: 2,
          label: "其他",
        },
      ],
      patForm: {
        isSelf: 1,
        name: "",
        cardTypeName: "身份证",
        cardType: 0,
        cardNum: "",
        age: "",
        sex: 1,
        phone: "",
      },
      // 解决键盘弹起底部按钮被顶起来
      isShowBottomBtn: true,
      docmHeight: document.documentElement.clientHeight, //默认屏幕高度
      showHeight: document.documentElement.clientHeight, //实时屏幕高度
    };
  },
  watch: {
    showHeight() {
      if (this.docmHeight > this.showHeight) {
        this.isShowBottomBtn = false;
      } else {
        this.isShowBottomBtn = true;
      }
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.queryPatId();
    }
    // window.onresize监听页面高度的变化
    window.onresize = () =>
      (() => {
        this.showHeight = document.body.clientHeight;
      })();
  },
  methods: {
    validator(val) {
      // 身份证时添加验证
      if (this.patForm.cardTypeName == "身份证") {
        const card15 =
          /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$/;
        const card18 =
          /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        return card15.test(val) || card18.test(val);
      } else {
        return true;
      }
    },
    // 获取年龄
    getAge() {
      if (this.patForm.cardTypeName == "身份证") {
        if (this.validator(this.patForm.cardNum)) {
          // 获取字符串6-9位
          let atiem = this.patForm.cardNum.substring(6, 10);
          atiem = Number(atiem);
          let ntime = new Date().getFullYear();
          this.patForm.age = ntime - atiem;
          // 获取性别
          if (parseInt(this.patForm.cardNum.substr(16, 1)) % 2 == 1) {
            this.patForm.sex = 1;
          } else {
            this.patForm.sex = 2;
          }
        }
      }
    },
    queryPatId() {
      this.$http
        .request("queryPatId", { id: this.$route.query.id })
        .then((res) => {
          this.patForm = this.columns.forEach((item) => {
            if (res.cardType == item.value) {
              res.cardTypeName = item.label;
            }
          });
          this.patForm = res;
        })
        .catch((err) => {});
    },
    onConfirm(value) {
      if (this.patForm.cardTypeName != value.label) {
        this.patForm.cardNum = "";
      }
      this.patForm.cardTypeName = value.label;
      this.patForm.cardType = value.value;
      this.showPicker = false;
    },
    onSubmit(values) {
      if (this.$route.query.id) {
        this.$http
          .request("patUpdate", {
            ...this.patForm,
            id: this.$route.query.id,
            patientId: this.$cache.local.getItem("patientId"),
          })
          .then((res) => {
            // console.log(res, "成功");
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$http
          .request("patAdd", {
            ...this.patForm,
            patientId: this.$cache.local.getItem("patientId"),
          })
          .then((res) => {
            // console.log(res, "成功");
            this.$router.go(-1);
          })
          .catch(() => {});
      }
      // console.log(values, "提交");
    },
  },
};
</script>

<style lang="scss" scoped>
.pat-wrapper {
  background-color: #fafafc;

  .van-form {
    background-color: #fff;
    height: calc(100% - 40px);
    position: relative;
  }
}
.btn-box {
  height: 100px;
  padding: 0 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.tips {
  line-height: 40px;
  font-size: 15px;
  color: #5d6e7f;
  margin: 0 15px;
}
</style>