<template>
  <div class="order-wrapper">
    <div class="top-bg" :class="classBgColor(orderDetail.orderStatus)">
      <!-- 订单状态 0 待支付 1已支付 ，2关闭，3已取消，4已完成 5进行中 -->
      <p>
        <span v-if="orderDetail.orderStatus == 0">订单待支付</span>
        <span v-if="orderDetail.orderStatus == 1">订单已支付</span>
        <span v-if="orderDetail.orderStatus == 2">订单已关闭</span>
        <!-- <span v-if="orderDetail.orderStatus == 3">订单已取消</span> -->
        <span v-if="orderDetail.orderStatus == 4">订单已完成</span>
        <span v-if="orderDetail.orderStatus == 5">订单进行中</span>
      </p>
      <img src="../assets/img/order/circle.png" alt="" />
    </div>
    <div class="pat-box">
      <img src="../assets/img/order/avatar.png" alt="" />
      <span class="name">{{ orderDetail.name }}</span>
      <span
        >{{ orderDetail.sex == 1 ? "男" : "女" }} |
        {{ orderDetail.age }}岁</span
      >
      <span>{{ orderDetail.phone }}</span>
    </div>
    <div class="doc-box">
      <div class="item-con">
        <p class="two">
          <span>
            <span class="name">{{ assistantOrder.doctorName }}</span>
            <span class="text"
              >{{
                convertDoctorProfessional(assistantOrder.doctorProfessional)
              }}
              | {{ assistantOrder.departmentName }}</span
            >
          </span>
          <span>
            <span>
              <span
                v-if="assistantOrder.serverType == '1'"
                class="type-color type-color1"
                >图文</span
              >
              <span
                v-if="assistantOrder.serverType == '2'"
                class="type-color type-color2"
                >语音</span
              >
              <span
                v-if="assistantOrder.serverType == '3'"
                class="type-color type-color3"
                >视频</span
              >
            </span>
          </span>
        </p>
        <p class="one">
          <span>{{ assistantOrder.organizationName }}</span>
        </p>
        <p class="two">
          <span class="time">{{ assistantOrder.createTime }}</span>
          <span class="text"
            >应付款 ￥{{ getServerPrice(assistantOrder.serverPrice) }}</span
          >
        </p>
        <p class="btn-box">
          <van-button
            plain
            round
            color="#9DA7B1"
            size="small"
            v-if="
              orderDetail.orderStatus == 2 ||
              orderDetail.orderStatus == 3 ||
              orderDetail.orderStatus == 4
            "
            @click.stop="handleDelete"
            >删除订单</van-button
          >
          <van-button
            plain
            round
            color="#9DA7B1"
            size="small"
            v-if="orderDetail.orderStatus == 0"
            @click.stop="handleCancel"
            >取消订单</van-button
          >
          <van-button
            plain
            round
            color="#9DA7B1"
            size="small"
            v-if="orderDetail.orderStatus == 5"
            @click.stop="inviteFamily"
            >邀请家属</van-button
          >
        </p>
      </div>
    </div>
    <div class="order-box">
      <p class="order-title">订单信息</p>
      <div>
        <span>创建时间</span><span>{{ orderDetail.createTime }}</span>
      </div>
      <div v-if="orderDetail.orderStatus == 2">
        <span>关闭时间</span><span>{{ orderDetail.closureTime }}</span>
      </div>
      <div>
        <span>订单编号</span
        ><span
          >{{ orderDetail.orderNum }} |
          <span @click="onCopy(orderDetail.orderNum)" style="color: #278eff"
            >复制</span
          ></span
        >
      </div>
	  <div v-if="assistantOrder.startConsultTime" >
	    <span class="td1">会诊时间</span
	    ><span class="td2">{{assistantOrder.startConsultTime}}</span>
	  </div>
      <template v-if="orderDetail.orderStatus == 4">
        <div>
          <span class="td1">会诊时长</span
          ><span class="td2">{{ orderDetail.timer }}</span>
        </div>
        <div>
          <span class="td1">病情概述</span
          ><span class="td2">{{ orderDetail.conditionContent }}</span>
        </div>
        <div>
          <span class="td1">主诉</span
          ><span class="td2">{{ orderDetail.recountContent }}</span>
        </div>
        <div><span>病历资料</span></div>
        <div>
          <div class="imgs" :class="imgList.length != 0 ? 'imgs-border' : ''">
            <template v-for="(item, index) in imgList" :key="index">
              <div
                class="imgs-img"
                v-if="item.type && item.type.indexOf('image/') == 0"
              >
                <img class="img" :src="item.url" @click="wiewImg([item.url])" />
              </div>
              <div
                class="imgs-video"
                v-else-if="item.type && item.type.indexOf('video/') == 0"
              >
                <video
                  @play="controlSingleVideo"
                  :src="item.url"
                  style="height: 200px; width: 100%; margin: 10px 0"
                  controls="controls"
                ></video>
              </div>

              <div class="imgs-file" v-else>
                <a :href="item.url" target="_blank" class="download-box">
                  <p class="file-name">
                    <span>{{ item.name || item.url }}</span>
                  </p>
                  <img src="../assets/img/icon/wenjian.png" />
                </a>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
    <div class="help-box">
      <p class="order-title">需要帮助？</p>
      <div class="help-con">
        <div @click="connectKf">
          <img src="../assets/img/order/msg.png" alt="" />
          <span>联系客服</span>
        </div>
        <div @click="callPhone">
          <img src="../assets/img/order/phone.png" alt="" />
          <span>拨打电话</span>
        </div>
      </div>
    </div>
    <div class="btn-box">
      <van-button
        class="btn-submit"
        type="primary"
        size="large"
        round
        v-if="orderDetail.orderStatus == 3 || orderDetail.orderStatus == 4"
        @click="toOnConsult"
        >再次咨询</van-button
      >
      <van-button
        class="btn-submit"
        type="primary"
        size="large"
        round
        v-if="orderDetail.orderStatus == 0"
        @click="yzf"
        >立即支付</van-button
      >
      <van-button
        class="btn-submit"
        type="primary"
        size="large"
        round
        v-if="orderDetail.orderStatus == 1"
        @click="toZxRoom"
        >导医咨询</van-button
      >
      <van-button
        class="btn-submit"
        type="primary"
        size="large"
        round
        v-if="orderDetail.orderStatus == 5"
        @click="toRoom"
        >接入对话</van-button
      >
    </div>
    <van-popup
      v-model:show="isShow.qrCode"
      closeable
      :close-on-click-overlay="false"
      :style="{ padding: '32px', background: '#6087CD', borderRadius: '20px' }"
    >
      <div class="qrcode-dialog">
        <span class="txt1">长按添加在线客服</span>
        <img src="../assets/img/qrcode2.png" alt="" />
        <span class="txt2">工作日9：00-18：00</span>
      </div>
    </van-popup>
    <!-- 邀请家属弹框 -->
    <van-dialog
      v-model:show="isShow.inviteFamily"
      title="邀请家属"
      confirm-button-text="复制"
      @confirm="copyYqText()"
      show-cancel-button
    >
      <div class="inviteFamilyText">
        <p>患者您好</p>
        <p>
          您可邀请一位家属协助您一并参与沟通。已为您生成分享链接，微信发送给您的家属，打开微信后验证通过即可参与沟通。
        </p>
      </div>
      <div class="inviteFamilyYzm">
        验证码：
        <span class="inviteFamilyYzmNum">{{
          inviteFamilyObj.invitationCode
        }}</span>
      </div>
      <div class="inviteFamilyLj">
        连接：
        <span class="inviteFamilyLjColor">{{
          inviteFamilyObj.invitationUrl
        }}</span>
      </div>
    </van-dialog>
  </div>
</template>
  
  <script>
import { Icon, Dialog, Button, Notify, Toast, ImagePreview, Popup } from "vant";
export default {
  name: "orderDetail",
  components: {
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
    [Button.name]: Button,
    [Notify.name]: Notify,
    [Popup.name]: Popup,
  },
  data() {
    return {
      formObj: {},
      assistantOrder: {},
      orderDetail: {},
      // seeDoctorPeople: {},
      // patientAssistantConsult: {},
      isLoading: {
        zf: false,
      },
      isShow: {
        inviteFamily: false,
        qrCode: false,
      },
      // 微信预订单
      weChatParameter: {},
      // 邀请家属参数集合
      inviteFamilyObj: {},
      imgList: [],
      // 会诊房间号
      huiRoomId: "",
    };
  },
  computed: {
    classBgColor() {
      // 订单状态 0 待支付 1已支付 ，2关闭，3已取消，4已完成 5进行中
      return function (val) {
        console.log(val, "6666666666");
        if (val == "3" || val == "4" || val == "1" || val == "2") {
          return "top-bg-color1";
        } else if (val == "0") {
          return "top-bg-color2";
        } else if (val == "5") {
          return "top-bg-color3";
        }
      };
    },
  },
  mounted() {
    this.getAssistantOrderDetailById(this.$route.query.orderId);
  },
  methods: {
    // 控制同一页面只有一个音频或视频在播放
    controlSingleVideo() {
      var videoList = document.getElementsByTagName("video");
      console.log(videoList, "videoList");
      for (let i = 0; i < videoList.length; i++) {
        let video = videoList[i];
        video.addEventListener("play", function (e) {
          console.log(video);
          console.log("提示该视频正在播放中");
          for (let j = 0; j < videoList.length; j++) {
            if (j !== i) videoList[j].pause();
          }
          for (let j = 0; j < audioList.length; j++) {
            audioList[j].pause();
          }
        });
      }
    },
    // 获取图片
    getImgList(l) {
      if (!l) {
        this.imgList = [];
        return;
      }
      try {
        let list = JSON.parse(l);
        this.imgList = list;
      } catch {
        this.imgList = [];
      }
    },
    getAssistantOrderDetailById(id) {
      this.$http
        .request("getAssistantOrderDetailById", {
          id,
        })
        .then((res) => {
          console.log(res, "22222222222222");
          if (res.assistantOrder) {
            res.assistantOrder.departmentName =
              res.assistantOrder.departmentName.split(",")[0];
            if (
              res.assistantOrder.startConsultTime &&
              res.assistantOrder.endConsultTime
            ) {
              res.orderDetail.timer = this.formatTimer(
                res.assistantOrder.startConsultTime,
                res.assistantOrder.endConsultTime
              );
            }
            this.assistantOrder = res.assistantOrder;
          }
          this.huiRoomId = res.huiRoomId;
          // this.formObj = res;
          if (res.orderDetail) {
            this.orderDetail = res.orderDetail;
            this.getImgList(this.orderDetail.caseImg);
          }
          // if (res.seeDoctorPeople) {
          //   this.seeDoctorPeople = res.seeDoctorPeople;
          // }
          // if (res.patientAssistantConsult) {
          //   this.patientAssistantConsult = res.patientAssistantConsult;
          // }
        })
        .catch((err) => {});
    },
    // 获取时间（格式化）
    formatTimer(startTime, endTime) {
      console.error(startTime, endTime, "计算");
      //将日期字符串转换为时间戳
      let sTime = new Date(startTime).getTime(); //开始时间
      let eTime = new Date(endTime).getTime(); //结束时间
      //作为除数的数字
      let divNumSecond = 1000;
      let divNumMinute = 1000 * 60;
      let divNumHour = 1000 * 3600;
      let divNumDay = 1000 * 3600 * 24;

      let day = parseInt((eTime - sTime) / parseInt(divNumDay));
      let hour = parseInt(
        ((eTime - sTime) % parseInt(divNumDay)) / parseInt(divNumHour)
      );
      let minute = parseInt(
        parseInt(
          ((eTime - sTime) % parseInt(divNumDay)) % parseInt(divNumHour)
        ) / parseInt(divNumMinute)
      );
      let second =
        (parseInt(
          ((eTime - sTime) % parseInt(divNumDay)) % parseInt(divNumHour)
        ) %
          parseInt(divNumMinute)) /
        parseInt(divNumSecond);
      let str = hour + "小时" + minute + "分" + second + "秒";
      console.log(str, "时间差");
      return str;
    },
    // 联系客服
    connectKf() {
      this.isShow.qrCode = true;
    },
    // 拨打电话
    callPhone() {
      Dialog.confirm({
        title: "400-863-1599",
        message: "服务时间：9：00-18：00（工作日）",
        confirmButtonColor: "#1989fa",
        confirmButtonText: "拨打电话",
      })
        .then(() => {
          // on confirm
          window.location.href = "tel:4008631599";
        })
        .catch(() => {
          // on cancel
        });
    },
    // 患者家属邀请
    copyYqText() {
      let t = `验证码：${this.inviteFamilyObj.invitationCode},连接：${this.inviteFamilyObj.invitationUrl}?roomId=${this.orderDetail.roomId}`;
      this.onCopy(t);
    },
    // 复制
    onCopy(context) {
      // 创建输入框元素
      let oInput = document.createElement("input");
      // 将想要复制的值
      oInput.value = context;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      // 弹出复制成功信息
      // Notify({ type: "success", message: "复制成功" });
      Toast.success("复制成功");
      // 复制后移除输入框
      oInput.remove();
    },
    // 删除订单
    handleDelete(item2) {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确定删除当前订单吗？",
        confirmButtonColor: "#1989fa",
      })
        .then(() => {
          this.$http
            .request("delAssistantOrder", {
              id: this.orderDetail.id,
            })
            .then((res) => {
              console.log(res, "删除订单删除订单删除订单删除订单");
              Toast.success("删除成功");
              this.$router.go(-1);
            })
            .catch((err) => {});
        })
        .catch(() => {});
    },
    // 取消订单
    handleCancel(item2) {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确定取消当前订单吗？",
        confirmButtonColor: "#1989fa",
      })
        .then(() => {
          this.$http
            .request("cancelOrder", {
              id: this.orderDetail.id,
            })
            .then((res) => {
              Toast.success("取消成功");
              this.getAssistantOrderDetailById(this.$route.query.orderId);
            })
            .catch((err) => {});
        })
        .catch(() => {});
    },
    // 进入咨询聊天室
    toZxRoom() {
      this.$router.push({
        path: "/yizhuChatRoom",
        query: {
          seeDoctorId: this.orderDetail.seeDoctorId,
          id: this.orderDetail.roomId,
        },
      });
    },
    // 会诊聊天室
    toRoom() {
      this.$router.push({
        path: "/chatRoom",
        query: {
          seeDoctorId: this.orderDetail.seeDoctorId,
          roomId: this.huiRoomId,
        },
      });
    },
    // 再次咨询
    toOnConsult() {
		this.$http.request("zxCount",{}).then(()=>{}).catch(()=>{})
      this.$router.push({
        path: "/onConsult",
        query: {
          // 医生id
          docId: this.orderDetail.doctorId,
		  seeDoctorId:this.orderDetail.seeDoctorId
        },
      });
    },
    // 点击支付
    yzf() {
      if (this.isLoading.zf) {
        Toast("正在支付中...");
        return;
      }
      this.isLoading.zf = true;

      this.weChatParameter = {};
      this.$http
        .request("getOrderJSAPIPrepay", { id: this.$route.query.orderId })
        .then((res) => {
          if (res && res.appId) {
            this.weChatParameter = res;
            this.weixinPay();
          } else {
			   let iframeSrc =window.location.origin+"/"+process.env.BASE_URL +"hzbLoginPage.html?uid=" +
							this.$cache.local.getItem("patientId")
							+"&returnUrl="+window.encodeURIComponent(window.location.href);
				window.location.replace(iframeSrc);
		  }
        })
        .catch(() => {
          this.isLoading.zf = false;
        });
    },

    //解决微信内置对象报错
    weixinPay() {
      let instance = Toast("请稍等...");
      var that = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            that.onBridgeReady(instance),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent(
            "WeixinJSBridgeReady",
            that.onBridgeReady(instance)
          );
          document.attachEvent(
            "onWeixinJSBridgeReady",
            that.onBridgeReady(instance)
          );
        }
      } else {
        that.onBridgeReady(instance);
      }
    },
    //微信内置浏览器类，weChatParameter对象中的参数是3.步骤代码中从后端获取的数据
    onBridgeReady(instance) {
      var that = this;
      console.error("支付前");
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: that.weChatParameter.appId, //公众号名称，由商户传入
          timeStamp: that.weChatParameter.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: that.weChatParameter.noticeStr, //随机串
          package: that.weChatParameter.prepayId,
          signType: "RSA", //微信签名方式：RSA
          paySign: that.weChatParameter.sign, //微信签名
          // jsApiList: ['chooseWXPay']
        },
        function (res) {
          console.error(res);
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            Toast.success("支付成功");
            that.getAssistantOrderDetailById(that.$route.query.orderId);
            // this.orderDetail.orderStatus = 1;
          } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
            //取消支付的操作
            instance.close();
            that.isLoading.zf = false;
          } else {
            //支付失败的操作
            instance.close();
            Toast("支付失败");
            that.isLoading.zf = false;
          }
        }
      );
    },
    inviteFamily() {
      let t = Toast("请稍等");
      this.inviteFamilyObj = {};
      this.$http
        .request("inviteFamily", { roomId: this.orderDetail.roomId })
        .then((res) => {
          t.clear();
          console.log(res);
          this.inviteFamilyObj = res;
          this.isShow.inviteFamily = true;
        })
        .catch(() => {});
    },
    convertDoctorProfessional(val) {
      if (val == 1) {
        return "主任医师";
      } else if (val == 2) {
        return "副主任医师";
      } else if (val == 3) {
        return "主治医师";
      } else if (val == 4) {
        return "医士";
      } else if (val == 5) {
        return "主任药师";
      } else if (val == 6) {
        return "副主任药师";
      } else if (val == 7) {
        return "主管药师";
      } else if (val == 8) {
        return "药士";
      } else if (val == 9) {
        return "主任护师";
      } else if (val == 10) {
        return "副主任护师";
      } else if (val == 11) {
        return "主管护师";
      } else if (val == 12) {
        return "护士";
      } else {
        return val;
      }
    },
    // 价格转换
    getServerPrice(n) {
      if (!n) {
        return n;
      }
      n = n / 100;
      return Number(n.toFixed(2));
    },
    wiewImg(l) {
      ImagePreview({
        images: l,
        // startPosition: 0,
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.order-wrapper {
  background-color: #f4f4f7;
  height: 100vh;
  .top-bg-color1 {
    background-color: #278eff;
  }
  .top-bg-color2 {
    background-color: #f79c42;
  }
  .top-bg-color3 {
    background-color: #2ac2a8;
  }
  .top-bg {
    color: #fff;
    font-size: 20px;
    height: 120px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    img {
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .pat-box {
    background-color: #fff;
    margin: 0 10px;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    align-items: center;
    position: relative;
    top: -30px;
    img {
      width: 30px;
    }
    span {
      margin-left: 15px;
      color: #272d3b;
    }
    .name {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .doc-box {
    width: 7.1rem;
    margin: 10px auto 0;
    height: 150px;
    background: #ffffff;
    color: #272d3b;
    box-shadow: 0px 2px 6px 0px rgba(225, 225, 225, 0.5);
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box;
    .item-img {
      width: 90px;
      height: 90px;
      border-radius: 8px;
      overflow: hidden;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .item-con {
      font-size: 14px;
      .two {
        display: flex;
        justify-content: space-between;

        .type-color {
          padding: 2px 5px;
          border-radius: 3px;
        }
        .type-color1 {
          background-color: #dfeeff;
          color: #278eff;
        }
        .type-color2 {
          background-color: #dff6f2;
          color: #2ac2a8;
        }
        .type-color3 {
          background-color: #fdf2e7;
          color: #f79c42;
        }
        .name {
          font-size: 16px;
          font-weight: bold;
          margin-right: 10px;
          color: #272d3b;
        }
        .text {
          color: #272d3b;
        }
        .status-color1 {
          color: #2ac2a8;
        }
        .status-color2 {
          color: #f79c42;
        }
        .time {
          color: #5d6e7f;
          font-size: 13px;
        }
      }
      .one {
        color: #5d6e7f;
        margin: 8px 0;
      }
      .btn-box {
        text-align: right;
        margin-top: 15px;
        .van-button {
          margin-left: 10px;
          padding: 0 15px;
        }
      }
    }
  }
  .order-box {
    width: 7.1rem;
    margin: 10px auto 0;
    min-height: 150px;
    background: #ffffff;
    color: #272d3b;
    box-shadow: 0px 2px 6px 0px rgba(225, 225, 225, 0.5);
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box;
    .order-title {
      font-size: 16px;
      font-weight: bold;
      margin-right: 10px;
      line-height: 45px;
      border-bottom: 1px solid #eee;
    }
    > div {
      display: flex;
      justify-content: space-between;
      line-height: 28px;
      .td1 {
        width: 6em;
      }
      .td2 {
        max-width: calc(100% - 6em);
      }
    }
  }
  .help-box {
    width: 7.1rem;
    margin: 10px auto 0;
    height: 125px;
    background: #ffffff;
    color: #272d3b;
    box-shadow: 0px 2px 6px 0px rgba(225, 225, 225, 0.5);
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box;
    .order-title {
      font-size: 16px;
      font-weight: bold;
      margin-right: 10px;
      line-height: 45px;
      border-bottom: 1px solid #eee;
    }
    .help-con {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 75px;
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 25px;
          margin: 0 8px;
        }
      }
    }
  }
  .btn-box {
    margin: 10px;
    margin-top: 30px;
  }
}
.inviteFamilyText {
  font-size: 15px;
  font-weight: 400;
  color: #5d6e7f;
  line-height: 24px;
  padding: 10px;
}
.inviteFamilyYzm {
  padding: 10px;
  font-size: 14px;
  line-height: 24px;
  color: #5d6e7f;
  .inviteFamilyYzmNum {
    font-weight: 600;
  }
}
.inviteFamilyLj {
  padding: 10px 10px 20px 10px;
  font-size: 14px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #5d6e7f;
  .inviteFamilyLjColor {
    color: #278eff;
  }
}
.imgs {
  overflow: hidden;
  .imgs-img {
    float: left;
    width: 1.2rem;
    height: 1.2rem;
    margin: 5px;
    img {
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }
  }
  .imgs-video {
    float: left;
    width: 100%;
    // height: 200px;
  }
  .imgs-file {
    .file-name {
      text-align: center;
    }
    border: 1px solid #ddd;
    padding: 4px;
    border-radius: 4px;
    float: left;
    img {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}
.qrcode-dialog {
  color: #fff;
  text-align: center;
  .txt1 {
    font-size: 22px;
    font-weight: bold;
  }
  .txt2 {
    font-size: 16px;
    font-weight: bold;
  }
  img {
    width: 200px;
    height: 200px;
    margin: 10px 0;
  }
}
</style>