<template>
  <div class="department-box">
    <div class="title">全部科室</div>
    <van-tree-select
      :class="isAreType == 1 ? 'tree-select-box' : ''"
      style="height: calc(100% - 50px)"
      v-model:active-id="activeId"
      v-model:main-active-index="activeIndex"
      :items="departmentAllList"
      @click-nav="changeDepartFirst($event, departmentAllList)"
      @click-item="changeDepartSecond"
    />
  </div>
</template>

<script>
import { TreeSelect } from "vant";
export default {
  name: "departmentAll",
  components: {
    [TreeSelect.name]: TreeSelect,
  },
  data() {
    return {
      isAreType: 0,
      activeId: 1,
      activeIndex: 0,
      departmentAllList: [],
    };
  },
  mounted() {
    this.isAreType = this.$route.query.isAreType;
    this.locationCity = this.$route.query.locationCity;
    this.hospitalId = this.$route.query.hospitalId;
    this.getDepartmentAll();
  },
  methods: {
    getDepartmentAll() {
      let url = "",
        postData = {};
      if (this.isAreType == 1) {
        // 区域
        url = "getDepartmentAllArea";
        postData.name = this.locationCity;
      } else {
        url = "getDepartmentAllHos";
        postData.organizationId = this.hospitalId;
      }
      this.$http
        .request(url, postData)
        .then((res) => {
          res.forEach((item) => {
            item.text = item.departmentAliasName;
            delete item.disabled;
            if (item.departments) {
              if (item.departments.length != 0) {
                item.children = item.departments;
                item.children.forEach((ele) => {
                  ele.text = ele.departmentAliasName;
                  delete ele.disabled;
                });
              }
              // else {
              // item.children = [
              //   {
              //     text: "暂无二级科室",
              //     departmentAliasName: item.departmentAliasName,
              //     id: item.id,
              //     organizationId: item.organizationId,
              //   },
              // ];
              // }
            }
          });
          this.departmentAllList = res;
        })
        .catch((err) => {});
    },
    // 标准科室-没有二级的一级点击直接跳转
    changeDepartFirst(index, arr) {
      if (!arr[0].children) {
        if (this.isAreType == 1) {
          this.$router.push(
            "/doctorPageArea?locationCity=" +
              this.locationCity +
              "&departId=" +
              arr[index].id +
              "&departName=" +
              arr[index].departmentAliasName +
              "&isAreType=" +
              this.$route.query.isAreType
          );
        } else {
          this.$router.push(
            "/doctorPageHospital?hospitalId=" +
              this.hospitalId +
              "&departId=" +
              arr[index].id +
              "&departName=" +
              arr[index].departmentAliasName +
              "&isAreType=" +
              this.$route.query.isAreType
          );
          console.log("区域");
        }
      }
    },
    // 二级点击跳转
    changeDepartSecond(item) {
      if (this.isAreType == 1) {
        this.$router.push(
          "/doctorPageArea?locationCity=" +
            this.locationCity +
            "&departId=" +
            item.id +
            "&departName=" +
            item.departmentAliasName
        );
        console.log("机构");
      } else {
        this.$router.push(
          "/doctorPageHospital?hospitalId=" +
            this.hospitalId +
            "&departId=" +
            item.id +
            "&departName=" +
            item.departmentAliasName
        );
        console.log("区域");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.department-box {
  height: 100vh;
  .title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    line-height: 50px;
  }
  .van-tree-select {
    height: 100%;
  }
  .tree-select-box {
    height: 100%;
    ::v-deep(.van-tree-select__content) {
      display: none;
    }
  }
}
</style>