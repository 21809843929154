<template>
  <div class="doc-wrapper">
    <div class="top-wrapper">
      <div class="top-search">
        <van-search
          v-model="searchInput"
          show-action
          placeholder="搜索科室或医生"
          @search="onClickButton"
          @clear="onClear"
        >
          <template #action>
            <div @click="onClickButton">搜索</div>
          </template>
        </van-search>
      </div>
      <div class="select-box">
        <van-field
          v-model="fieldValueDepart"
          is-link
          readonly
          placeholder="选择科室"
          @click="showPicker.depart = true"
        />
      </div>
    </div>

    <div class="doctor-list" @click="handleDoctor">
      <div v-if="doctorList.length != 0">
        <div
          v-for="item in doctorList"
          :key="item.id"
          class="doctor-item"
          @click="toDetail(item)"
        >
          <van-image
            v-if="item.doctorHeadImg"
            round
            width="1rem"
            height="1rem"
            fit="contain"
            :src="item.doctorHeadImg"
          />
          <van-image
            v-else
            round
            width="1rem"
            height="1rem"
            fit="contain"
            src="http://maicc-test.oss-cn-beijing.aliyuncs.com/temp-folder/2023-02-01%5Cf5a4f8e4-4003-454f-84a1-4603d722ecd6-6a48ae42-04da-4b8a-9fe2-af62b1e4fc99.png"
          />
          <div class="doctor-rgt">
            <p class="rgt-one">
              <span>
                <span class="name">{{ item.doctorName }}</span>
                <span class="text"
                  >{{
                    item.departmentName ? item.departmentName.split(",")[0] : ""
                  }}
                  |
                  {{ convertDoctorProfessional(item.doctorProfessional) }}</span
                >
                <span v-if="item.isDoctorTeam == 1" class="team-box">团队</span>
                <span v-if="item.isOnTheJob == 1" class="team-box2">合作</span>
              </span>
              <span>
                <van-icon
                  v-if="item.isFollow == 1"
                  name="star"
                  color="#F79C42"
                  @click.stop="cancelFollowDoctor(item)"
                />
                <van-icon
                  v-else
                  name="star"
                  color="#E2E1E4"
                  @click.stop="followDoctor(item)"
                />
              </span>
            </p>
            <p class="doctor-text">{{ item.doctorGood }}</p>
            <p>
              <span
                class="doctor-tag"
                v-for="item2 in item.dptTags"
                :key="item2.id"
                >{{ item2.roleName }}</span
              >
            </p>
          </div>
        </div>
      </div>
      <div v-else class="empty-text">暂无专家推荐</div>
    </div>
    <div class="bot-wrapper">
      <div class="bot-msg">
        <span>
          <img
            src="../assets/img/doc1.png"
            alt=""
            style="width: 30px; margin-right: 10px"
          />
          不知道选择哪个科室？
        </span>
        <span class="zixun" @click="handleSave">
          在线咨询
          <img src="../assets/img/lft2.png" alt="" style="width: 20px" />
        </span>
      </div>
    </div>
  </div>
  <van-popup
    v-model:show="showPicker.depart"
    round
    position="bottom"
    class="depart-box"
  >
    <div class="all-depart" @click="lookMore">全部科室 ></div>
    <van-picker
      :columns="columnsDepart"
      :columns-field-names="{ text: 'departmentAliasName', value: 'id' }"
      @cancel="showPicker.depart = false"
      @confirm="onConfirmDepart"
    />
  </van-popup>
</template>
    
    <script>
import {
  Picker,
  Toast,
  Field,
  Popup,
  Cell,
  Calendar,
  Button,
  Icon,
  Tabs,
  Tab,
  Search,
  Dialog,
  Image,
  Notify,
} from "vant";

export default {
  name: "doctorPageHospital",
  components: {
    [Picker.name]: Picker,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [Calendar.name]: Calendar,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Search.name]: Search,
    [Dialog.name]: Dialog,
    [Image.name]: Image,
    [Notify.name]: Notify,
  },
  data() {
    return {
      searchInput: "",
      ListActive: "0",
      showPicker: {},
      columnsDepart: [],
      fieldValueDepart: "",
      doctorList: [],
      patList: [],
      isShow: {
        patList: false,
      },
    };
  },
  created() {},
  mounted() {
    if (this.$route.query.departId) {
      this.fieldValueDepartId = this.$route.query.departId;
      this.fieldValueDepart = this.$route.query.departName;
      console.log(this.fieldValueDepart, " this.fieldValueDepart11111111");
    }
    // if (this.$route.query.searchInput) {
    //   this.searchInput = this.$route.query.searchInput;
    // }
    if (this.$cache.session.getItem("searchInput")) {
      this.searchInput = this.$cache.session.getItem("searchInput");
    }
    this.queryOrganizationId();
    this.queryDepartment(this.$route.query.hospitalId);
  },
  methods: {
    // 搜索
    onClickButton() {
      this.$cache.session.setItem("searchInput", this.searchInput);
      this.queryOrganizationId();
    },
    // 清除
    onClear() {
      this.$cache.session.removeItem("searchInput");
      this.queryOrganizationId();
    },
    // 医生列表
    queryOrganizationId() {
      this.$http
        .request("queryOrganizationId", {
          patientId: this.$cache.local.getItem("patientId"),
          organizationId: this.$route.query.hospitalId,
          departmentId: this.fieldValueDepartId,
          searchInput: this.searchInput,
        })
        .then((res) => {
          console.log(res, "55555555");
          this.doctorList = res[0].doctorVos;
        })
        .catch((err) => {});
    },
    // 科室-选择查询
    queryDepartment(val) {
      this.$http
        .request("queryDepartment", {
          organizationId: val,
        })
        .then((res) => {
          // 后台返回字段有disabled（是否启用服务），关键字，会把picker选项禁用
          res.forEach((item) => {
            delete item.disabled;
          });
          this.columnsDepart = res;
        })
        .catch((err) => {});
    },

    // 科室弹窗确认
    onConfirmDepart(selectedOptions) {
      console.log(selectedOptions, "selectedOptions");
      this.showPicker.depart = false;
      this.fieldValueDepart = selectedOptions.departmentAliasName;
      this.fieldValueDepartId = selectedOptions.id;
      this.queryOrganizationId();
    },
    // 查看全部科室
    lookMore() {
      this.$router.push(
        "/departmentAll?hospitalId=" +
          this.$route.query.hospitalId +
          "&isAreType=" +
          this.$route.query.isAreType
      );
    },

    // 关注
    followDoctor(item) {
      Toast.loading();
      this.$http
        .request("followDoctor", {
          id: this.$cache.local.getItem("patientId"),
          doctorId: item.id,
        })
        .then((res) => {
          // Notify({ type: "success", message: "关注成功" });
          Toast.success("关注成功");
          this.queryOrganizationId();
        })
        .catch((err) => {});
    },
    // 取消关注
    cancelFollowDoctor(item) {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确认取消关注此医生吗？",
        confirmButtonColor: "#1989fa",
      })
        .then(() => {
          Toast.loading();
          this.$http
            .request("cancelFollowDoctor", {
              id: this.$cache.local.getItem("patientId"),
              doctorId: item.id,
            })
            .then((res) => {
              Toast.success("取关成功");
              this.queryOrganizationId();
            })
            .catch((err) => {});
        })
        .catch(() => {
          // on cancel
        });
    },
    // 详情
    toDetail(item) {
		this.$http.request("cardCount",{id:item.id}).then(()=>{}).catch(()=>{})
      this.$router.push("/doctorDetail?docId=" + item.id);
    },
    convertDoctorProfessional(val) {
      if (val == 1) {
        return "主任医师";
      } else if (val == 2) {
        return "副主任医师";
      } else if (val == 3) {
        return "主治医师";
      } else if (val == 4) {
        return "医士";
      } else if (val == 5) {
        return "主任药师";
      } else if (val == 6) {
        return "副主任药师";
      } else if (val == 7) {
        return "主管药师";
      } else if (val == 8) {
        return "药士";
      } else if (val == 9) {
        return "主任护师";
      } else if (val == 10) {
        return "副主任护师";
      } else if (val == 11) {
        return "主管护师";
      } else if (val == 12) {
        return "护士";
      } else {
        return val;
      }
    },
    // 在线咨询
    handleSave() {
		this.$http.request("zxCount",{}).then(()=>{}).catch(()=>{})
      this.$router.push("/onConsult");
    },
  },
  beforeDestroy() {},
};
</script>
      
    
    <style lang="scss" scoped>
.doc-wrapper {
  position: relative;
  height: 100%;
  .top-wrapper {
    height: 100px;
    width: 100%;
    background-color: #fff;
    position: fixed;
    top: 0;
    border-bottom: 1px solid #eee;
    .top-search {
      height: 55px;
    }
    .select-box {
      display: flex;
    }
  }
  .bot-wrapper {
    width: 100%;
    background-color: #fff;
    height: 70px;
    line-height: 70px;
    position: fixed;
    bottom: 0;
    .bot-msg {
      width: calc(100% - 30px);
      background-color: #e8f3ff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px;
      padding: 0 15px;
      box-sizing: border-box;
      border-radius: 8px;
      height: 50px;
      font-size: 15px;
      position: fixed;
      bottom: 0;
      span {
        display: flex;
        align-items: center;
      }
      .zixun {
        color: #278eff;
      }
    }
  }
}

.doctor-list {
  // background-color: rgb(127, 180, 255);
  height: calc(100% - 170px);
  overflow: auto;
  position: fixed;
  top: 100px;
  width: 100%;
  .doctor-item {
    display: flex;
    // justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 15px;
    box-sizing: border-box;
    width: 100%;

    .doctor-rgt {
      margin-left: 15px;
      width: calc(100% - 80px);
      .rgt-one {
        display: flex;
        justify-content: space-between;
        .name {
          font-size: 16px;
          color: #272d3b;
          font-weight: bold;
        }
        .text {
          font-size: 13px;
          margin-left: 10px;
          color: #272d3b;
        }
        .cancel-follow {
          color: #9da7b1;
        }
        .team-box {
          color: #278eff;
          background-color: #e8f3fe;
          font-size: 12px;
          padding: 2px 5px;
          border-radius: 2px;
          margin-left: 5px;
        }
        .team-box2 {
          color: #2ac2a8;
          background-color: #d8fcf6;
          font-size: 12px;
          padding: 2px 5px;
          border-radius: 2px;
          margin-left: 5px;
        }
      }

      .doctor-text {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 13px;
        color: #b8c0c7;
        margin: 10px 0;
      }
      .doctor-tag {
        background-color: #fff5e6;
        color: #f79c42;
        font-size: 12px;
        border-radius: 2px;
        margin-right: 10px;
        padding: 0 5px;
      }
    }
  }
  .empty-text {
    color: #9da7b1;
    font-size: 16px;
    text-align: center;
    margin-top: 30px;
  }
}
.depart-box {
  position: relative;
  .all-depart {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    color: #278eff;
    font-size: 14px;
  }
}
</style>