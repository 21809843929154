<template>
    <div class="xy-box">
      <h3 class="title">医生合作协议</h3>
      <div class="content">
        <p>
          本协议是会诊帮医生集团（深圳）有限公司（以下简称“会诊帮”）就医生在会诊帮及会诊帮旗下其他机构开通互联网咨询服务所订立的协议，请医生仔细阅读、认真理解本协议，并重点阅读责任限制及免除条款。
        </p>
        <p>
          本协议的构成包括协议条款正文，以及会诊帮平台的隐私政策、服务协议等开通会诊帮平台账户及使用会诊帮平台功能所需签署的协议。
        </p>
        <p class="first">第1条 协议的适用及基本定义</p>
        <p>
          1.1.本协议是医生通过会诊帮平台向用户提供互联网健康咨询服务（以下简称“互联网咨询服务”）所订立的协议。
        </p>
        <p>
          1.2.本协议项下为了保证医生提供的服务专业性，以及提高服务质量，医生需保证其订立本协议、提供互联网咨询服务前具备提供服务的资格，包括但不限于具备医师执业资格许可证或其他执业医师所应当具备的条件。
        </p>
        <p>
          1.3.本协议项下的互联网咨询服务是指医生通过会诊帮平台在线向用户提供与健康相关的咨询问答服务，包括但不限语音、文字、图片形式的服务，不包括向用户提供任何诊疗、诊断、治疗、病症、药品等诊疗服务。
        </p>
        <p>
          1.4.在医生点击、勾选“同意”按钮后，即表示医生同意本协议的所有条款，本协议即时生效。
        </p>
        <p class="first">第2条 医生的权利义务</p>
        <p>
          2.1.一旦医生选择在会诊帮平台（以下简称“本平台”）上开通互联网咨询服务，即被视为该医生能且将通过图文、语音、视频等方式为平台用户提供健康咨询服务;医生应保证其承诺的服务时间和质量。
        </p>
        <p>
          2.2.医生保证在本平台上提供的互联网咨询服务为本人亲自提供。如用户向本平台投诉提供服务的人员并非用户选择的人员，本平台有权直接冻结会诊帮平台的医生账号，并保留向医生追偿的权利。
        </p>
        <p>
          2.3.医生提供服务的过程中，医生同意并授权本平台使用医生的肖像进行与本协议业务有关的宣传推广，授权包含以下内容：医生许可本平台对带有医生本人姓名、肖像等的图片、照片、底片及其他影像资料享有免费使用权（但此期限不约束本平台对著作权归属于本平台的作品的使用）；本平台在使用带有医生本人姓名、肖像等的图片、照片、底片及其他影像资料时，医生同意将原始作品根据商业用途进行合理的改动和加工；本平台有权以营利或非营利为目的，在自有平台或本平台指定的第三方平台上使用医生姓名、肖像等的全部或局部。医生授权本平台以宣传为目的使用相关信息，但不可损害医生名誉、声誉及其他人格权利。
        </p>
        <p>
          2.4.本平台为医生提供安全、稳定的服务载体，如在服务过程中因网站系统原因导致服务不能完成的，本平台将与医生沟通重新安排服务，医生应该接受本平台的服务安排。
        </p>
        <p>
          2.5.医生在咨询服务中提供的建议均为其根据自身知识和经验提供的个人建议，本平台禁止医生在本平台上开展诊疗活动（包括但不限于在线问诊、提供治疗方案、诊断意见等行为），或开具任何类型的处方。如因医生违反本条规定造成本平台用户遭受损失的，由医生自行承担相关责任，如因此给本平台造成损失的，本平台有权向该医生追偿。
        </p>
        <p>
          2.6.本平台对于已收录的医生页面，医生本人须进行实名注册认证，此操作需医生本人进行，医生需填写真实姓名、办公电话等真实信息，注册成功后，本平台将对医生进行身份认证（需提供医生本人正确的身份证号码）、医师资格认证（需照片认证）、医院级别认证、个人简介和擅长的认证，认证通过者，将给予其认证标志，允许其以医生的名义提供咨询服务、发表文章等相关活动。如其提供信息不能证明为医生本人操作，本平台有权将该医生网页认证关联信息删除。
        </p>
        <p>
          2.7.医生不得将涉及医疗纠纷的问题或其它有责任争议的问题在本平台发布。涉及医疗纠纷的问题，本平台有权将此类信息删除，请医生另行咨询律师或相关主管部门寻求援助。
        </p>
        <p class="first">第3条 会诊帮的权利义务</p>
        <p>3.1.会诊帮应为医生提供服务平台，并保证平台的稳定运行。</p>
        <p>
          3.2.本平台有权根据其制定的评价制度对医生进行排名，本平台承诺，对医生进行排名的标准主要基于用户的评价、医生的服务质量等客观指标。
        </p>
        <p>
          3.3.如有第三方向本平台告知医生有违法或不当行为的，本平台仅以普通非专业医疗人员的知识水平标准对相关内容进行判别，对于可以明显认为内容或行为具有违法或不当性质的，本平台有权根据不同情节严重程度选择保留或删除相关部分或全部信息或停止对该医生提供服务，并追究医生相关法律责任。
        </p>
        <p class="first">第4条 费用结算条款</p>
        <p>
          4.1.本平台按约定向医生支付相应的报酬，但医生通过指定的第三方收取相应报酬的按照医生与第三方的约定结算，与本平台无关。会诊帮有权代扣代缴医生报酬的个人所得税（或其他相应税费，如有），相关税费由医生个人承担。若医生通过第三方收取相应报酬的，由此产生纠纷均由医生与第三方协商解决，与本平台无关。
        </p>
        <p>
          4.2.结算周期：按自然月月结算。本平台于每月前15个工作日解冻上月的服务报酬，医生可直接在线提取。通过第三方结算的，另行依据约定周期结算，不适用本条。
        </p>
        <p>
          4.3.本平台将不定期审查用户咨询服务的真实性，注册医生如通过本平台违规开展互联网诊疗活动、或违规开具任何处方、或使用虚假、欺诈手段等方式，获取收益，经本平台审查确认将扣除所有款项；情况严重者，本平台将停止和医生合作，并追究赔偿和法律责任。
        </p>
        <p class="first">第5条 责任限制条款</p>
        <p>5.1.本平台提供的所有服务，均为在现有技术条件下能够实现的服务。</p>
        <p>
          5.2.对于在本平台上发布的信息，仅以普通非专业人员的身份进行审核，医生应对所发布的信息承担相应责任。请医生对自己发布的信息采取严谨的态度，如因医生发布的信息产生纠纷的，本平台不承担任何责任且有权向医生追偿因此所遭受的全部损失。
        </p>
        <p>
          5.3.医生因在本平台上提供互联网咨询服务与其所在医疗机构发生纠纷的，本平台不承担任何责任。给本平台造成损失的，本平台有权向医生追偿。
        </p>
        <p>
          5.4.医生承诺不在本平台上开展任何违规互联网诊疗活动，或开具任何违规处方，如因此给用户造成损失的，本平台不承担任何责任，如因此给本平台造成损失的，本平台有权向医生追偿。
        </p>
        <p>5.5.医生在本平台上下载的信息，本平台不保证不包含任何的病毒信息。</p>
        <p>
          5.6.医生同意，为保障医生和客户的合法权益，本平台将对医生在本平台上发布的信息进行备份，但没有义务为医生提供其在本平台上发布的信息。
        </p>
        <p>
          5.7.因不可抗力因素或发生本平台无法控制的其他事件导致医生在本平台提供互联网咨询服务时遭受损失，本平台不承担赔偿责任。
        </p>
        <p>5.8.本平台不对医生在本平台中发布的相关数据的删除或者丢失负责。</p>
        <p>
          5.9.医生理解并同意，因市场变化或商业考虑，本平台在征得医生同意的情况下，可更改服务内容、费用、方式等。
        </p>
        <p>
          5.10.医生使用本平台服务所存在的风险将完全由其自己承担；因其使用本平台服务而产生的一切后果也由其自己承担，本平台对医生不承担任何责任。
        </p>
        <p class="first">第6条 服务条款的更新和服务变动、中断与终止</p>
        <p>
          6.1.医生理解，因法律法规的调整或出于商业考虑，本平台的服务条款将不定期进行更新，本平台提供的服务也有可能发生变动。本平台服务条款的更新将会及时体现在本平台我的账户上，医生须保持关注，更新的服务条款在本平台上发布，即成为本协议的有效组成部分。
        </p>
        <p>
          6.2.医生同意，如医生在本平台上的行为违反法律法规或者本协议的规定，则本平台有权中断或终止向该医生提供本协议项下的所有服务，如因此对本平台造成损失的，本平台有权要求医生赔偿损失。
        </p>
        <p>
          6.3.如发生下列任何一种情形，本平台有权随时中断或终止向医生提供本服务条款项下的网络服务而无需通知医生：
        </p>
        <p>6.3.1 医生自愿注销；</p>
        <p>6.3.2 医生违反本服务条款或国家有关法律法规；</p>
        <p>6.3.3 医生被行政或司法机构拘留或起诉；</p>
        <p>6.3.4 医生认证用户被有关部门吊销执业医师证书；</p>
        <p>6.3.5 医生提供的个人资料不真实；</p>
        <p>6.3.6 其它医生与本平台协商一致的需要终止的情况。</p>
        <p class="first">第7条 法律管辖和适用</p>
        <p>
          7.1.本协议的订立、履行、解释及争议解决均适用中华人民共和国（仅限大陆地区）有效法律。
        </p>
        <p>
          7.2.缔约双方因本协议的相关事宜发生争议的，应通过友好协商解决，协商不成的，任何一方有权向上海仲裁委员会按照该会仲裁规则申请仲裁。仲裁裁决是终局的，对双方均有约束力。
        </p>
        <p>
          7.3.鉴于双方是基于本平台进行线上合作的，本平台可根据实际情况调整平台的服务条款，若会诊帮调整服务条款的将在本平台公示或提示医生，若医生未向平台提出异议的，则视为医生接受调整后的服务条款。同时，鉴于医生系基于在本平台相关账号开展在线操作的，本平台的相关隐私政策、服务协议等在线文件为本条款不可分割的一部分，与本条款正文具有同等法律效力。
        </p>
      </div>
    </div>
  </template>
    
    <script>
  </script>
    
  <style scoped lang="scss">
  .xy-box {
    .title {
      text-align: center;
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 10px;
    }
    .content {
      line-height: 20px;
      color: #3c4247;
      font-size: 13px;
      text-align: justify;
      p {
        margin-top: 10px;
      }
      .first {
        font-weight: bold;
        color: #121314;
        font-size: 14px;
      }
    }
  }
  </style>