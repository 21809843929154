<template>
  <div class="pat-wrapper">
    <div ref="" class="content">
      <div class="top">
        <h2>{{ patInfo.name }}</h2>
        <p class="id-card">
          {{ convertCartType(patInfo.cardType) }}：{{ patInfo.cardNum }}
        </p>
      </div>
      <van-cell-group>
        <!-- <van-cell title="名字" :value="patInfo.name" />
      <van-cell title="身份证" :value="patInfo.idCard" /> -->
        <van-cell title="年龄" :value="patInfo.age + '岁'" />
        <van-cell title="性别" :value="patInfo.sex">
          <template #value>
            <span>{{ patInfo.sex == 1 ? "男" : "女" }}</span>
          </template>
        </van-cell>
        <van-cell title="手机号" :value="patInfo.phone" />
      </van-cell-group>
    </div>
    <div class="btn-box">
      <van-button plain round type="primary" @click="handleDelete"
        >删除就诊人</van-button
      >
      <van-button round type="primary" @click="handleEdit">编辑信息</van-button>
    </div>
  </div>
</template>

<script>
import { Cell, CellGroup, Button, Dialog } from "vant";
export default {
  name: "patientInfo",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
  },
  data() {
    return {
      patInfo: {},
      patId: "",
    };
  },
  mounted() {
    this.patId = this.$route.query.id;
    this.queryPatId();
  },
  methods: {
    queryPatId() {
      this.$http
        .request("queryPatId", { id: this.patId })
        .then((res) => {
          this.patInfo = res;
        })
        .catch((err) => {});
    },
    handleEdit() {
      this.$router.push("/patientAdd?id=" + this.patId);
    },
    handleDelete() {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确定删除当前就诊人吗? 删除后就诊人数据即被清空，就诊人信息无法恢复",
        confirmButtonColor: "#1989fa",
        confirmButtonText: "确定",
      })
        .then(() => {
          let idsText = "?ids=" + this.patInfo.id;
          this.$http
            .request("patDelete", idsText)
            .then((res) => {
              console.log(res, "成功");
              this.$router.replace("/?active=3");
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    convertCartType(val) {
      if (val == 0) {
        return "身份证";
      } else if (val == 1) {
        return "护照";
      } else {
        return "其他";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pat-wrapper {
  height: 100vh;
  background-color: #fafafc;
  .content {
    height: calc(100vh - 100px);
    .top {
      background-color: #fff;
      padding: 15px;
      .id-card {
        color: #5d6e7f;
        line-height: 30px;
        font-size: 15px;
      }
    }
  }

  .btn-box {
    line-height: 100px;
    margin: 0 15px;
    display: flex;
    justify-content: space-around;
    .van-button {
      width: 45%;
    }
  }
}
</style>