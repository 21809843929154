<template>
  <div class="doctor-wrapper">
    <div :class="docId ? 'top-box-dialog' : 'top-box'"></div>
    <div class="doc-box">
      <div class="flw-btn-box" v-if="docId">
        <span
          class="flw-btn flw-close"
          v-if="formObj.isFollow == 1"
          @click.stop="cancelFollowDoctor(formObj)"
          >取关</span
        >
        <span
          class="flw-btn flw-open"
          v-else
          @click.stop="followDoctor(formObj)"
          >关注</span
        >
      </div>
      <van-image
        v-if="formObj.doctorHeadImg"
        width="1.5rem"
        height="2rem"
        fit="contain"
        :src="formObj.doctorHeadImg"
      />
      <van-image
        v-else
        width="1.5rem"
        height="2rem"
        fit="contain"
        src="http://maicc-test.oss-cn-beijing.aliyuncs.com/temp-folder/2023-02-01%5Cf5a4f8e4-4003-454f-84a1-4603d722ecd6-6a48ae42-04da-4b8a-9fe2-af62b1e4fc99.png"
      />
      <div>
        <p>
          <span class="name">{{ formObj.doctorName }}</span>
          <span>
            {{ convertDoctorProfessional(formObj.doctorProfessional) }}</span
          >
        </p>
        <p class="text">
          {{ formObj.departmentJobName }}
        </p>
        <p class="text">
          {{ formObj.organizationJobName }}
        </p>
        <p style="margin-top: 5px">
          <span
            class="doctor-tag"
            v-for="item2 in formObj.dptTags"
            :key="item2.id"
            >{{ item2.roleName }}</span
          >
        </p>
      </div>
    </div>
    <div class="jianjie-box">
      <div class="title">
        <img class="img" src="../assets/img/icon/ti.png" alt="" />
        <h3>专业擅长</h3>
      </div>
      <div class="content">
        {{ formObj.doctorGood }}
      </div>
    </div>
    <div class="jianjie-box">
      <div class="title">
        <img class="img" src="../assets/img/icon/ti.png" alt="" />
        <h3>个人简介</h3>
      </div>
      <div class="content">
        {{ formObj.doctorIntro }}
      </div>
    </div>
	<div class="jianjie-box">
	  <div class="title">
	    <img class="img" src="../assets/img/icon/ti.png" alt="" />
	    <h3>会诊价格</h3>
	  </div>
	  <div v-show="item.serverStatus==1" v-for="item in formObj.doctorCosts" class="content">
	    <span>{{ getServerTypeName(item.serverType) }}：</span>
	    <span class="place-money"
	      >{{ getServerPrice(item.doctorPrice) }}元</span
	    >
	  </div>
	</div>
    <div class="btn-box">
      <van-button
        class="btn-submit"
        type="primary"
        size="large"
        round
        v-if="!docId"
        @click="handleSave"
        >在线咨询</van-button
      >
    </div>
  </div>
</template>
  
  <script>
import { Icon, Dialog, Button, Image, Popup, Picker, Toast } from "vant";
export default {
  name: "doctorDetail",
  components: {
    [Icon.name]: Icon,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Image.name]: Image,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
  },
  props: ["docId"],
  data() {
    return {
      formObj: {
        departments: [],
        organizationS: [],
      },
      patList: [],
      isShow: {
        patList: false,
      },
    };
  },

  mounted() {
    if (this.docId) {
      this.getDetail(this.docId);
    }
    if (this.$route.query.docId) {
      this.getDetail(this.$route.query.docId);
    }
  },
  methods: {
    // 关注
    followDoctor(item) {
      Toast.loading();
      this.$http
        .request("followDoctor", {
          id: this.$cache.local.getItem("patientId"),
          doctorId: item.id,
        })
        .then((res) => {
          // Notify({ type: "success", message: "关注成功" });
          Toast.success("关注成功");
          this.getDetail(this.docId);
        })
        .catch((err) => {});
    },
    // 取消关注
    cancelFollowDoctor(item) {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确认取消关注此医生吗？",
        confirmButtonColor: "#1989fa",
      })
        .then(() => {
          Toast.loading();
          this.$http
            .request("cancelFollowDoctor", {
              id: this.$cache.local.getItem("patientId"),
              doctorId: item.id,
            })
            .then((res) => {
              Toast.success("取关成功");
              this.getDetail(this.docId);
            })
            .catch((err) => {});
        })
        .catch(() => {
          // on cancel
        });
    },
    getDetail(id) {
      console.log(id, "565656565656");
      this.$http
        .request("queryDoctorId", { id })
        .then((res) => {
          console.log(res, "23232323232323232");
          this.formObj = res;
        })
        .catch(() => {});
    },
    convertDoctorProfessional(val) {
      if (val == 1) {
        return "主任医师";
      } else if (val == 2) {
        return "副主任医师";
      } else if (val == 3) {
        return "主治医师";
      } else if (val == 4) {
        return "医士";
      } else if (val == 5) {
        return "主任药师";
      } else if (val == 6) {
        return "副主任药师";
      } else if (val == 7) {
        return "主管药师";
      } else if (val == 8) {
        return "药士";
      } else if (val == 9) {
        return "主任护师";
      } else if (val == 10) {
        return "副主任护师";
      } else if (val == 11) {
        return "主管护师";
      } else if (val == 12) {
        return "护士";
      } else {
        return val;
      }
    },
    // 在线咨询
    handleSave() {
		this.$http.request("zxCount",{}).then(()=>{}).catch(()=>{})
      this.$router.push({
        path: "/onConsult",
        query: {
          docId: this.formObj.id,
        },
      });
    },
	// 获取服务价格名称
	getServerTypeName(t) {
	  if (t == 1) {
	    return "图文会诊服务";
	  } else if (t == 2) {
	    return "语音会诊服务";
	  } else if (t == 3) {
	    return "视频会诊服务";
	  }
	},
	// 获取价格 元
	getServerPrice(n) {
	  if (!n) {
	    return n;
	  }
	  n = n / 100;
	  return Number(n.toFixed(2));
	},
  },
};
</script>
  
  <style lang="scss" scoped>
.doctor-wrapper {
  .top-box {
    background: -webkit-linear-gradient(top, #278eff, white);
    height: 130px;
  }
  .top-box-dialog {
    background: -webkit-linear-gradient(top, #278eff, white);
    height: 130px;
    margin-top: -25px;
  }
  .doc-box {
    background-color: #fff;
    box-shadow: 0px 2px 6px 0px rgba(225, 225, 225, 0.5);
    margin: 0 15px;
    border-radius: 8px;
    margin-top: -90px;
    display: flex;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    .flw-btn-box {
      position: absolute;
      top: 8px;
      right: 5px;
      .flw-btn {
        // background-color: #f6ecec;
        // color: #45978d;
        padding: 3px 10px;
        border-radius: 5px;
      }
      .flw-open {
        background-color: #2ac2a8;
        color: #fff;
      }
      .flw-close {
        background-color: #f79d43;
        color: #fff;
      }
    }
    .van-image {
      margin-right: 10px;
    }
    span {
      // margin-left: 15px;
      color: #272d3b;
    }
    .name {
      font-size: 18px;
      font-weight: bold;
      margin-right: 10px;
      color: #333;
    }
    .text {
      color: #5d6e7f;
      font-size: 13px;
      line-height: 20px;
      margin-top: 5px;
    }
    .doctor-tag {
      background-color: #fff5e6;
      color: #f79c42;
      font-size: 12px;
      border-radius: 2px;
      margin-right: 10px;
      padding: 0 5px;
    }
  }
  .jianjie-box {
    margin: 15px;
    padding: 15px;
    box-sizing: border-box;
    background-color: #f3f9ff;
    border-radius: 8px;
    .title {
      display: flex;
      align-items: center;
      line-height: 40px;
      font-size: 16px;
      color: #333;
      .img {
        width: 8px;
        height: 20px;
        margin-right: 8px;
      }
    }
    .content {
      color: #5d6e7f;
      font-size: 14px;
      line-height: 28px;
    }
  }
  .btn-box {
    margin: 10px;
    margin-top: 30px;
  }
}
</style>