<template>
  <div class="top-search">
    <van-search
      v-model="docName"
      show-action
      placeholder="搜索医生"
      @search="onClickButton"
    >
      <template #action>
        <div @click="onClickButton">搜索</div>
      </template>
    </van-search>
    <div class="doctor-list">
      <div v-if="1">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          v-model:error="error"
          error-text="请求失败，点击重新加载"
          @load="getDoctorList"
        >
          <div
            v-for="item in doctorList"
            :key="item.id"
            class="doctor-item"
            @click="toDetail(item)"
          >
            <van-image
              v-if="item.doctorHeadImg"
              round
              width="1rem"
              height="1rem"
              fit="contain"
              :src="item.doctorHeadImg"
            />
            <van-image
              v-else
              round
              width="1rem"
              height="1rem"
              fit="contain"
              src="http://maicc-test.oss-cn-beijing.aliyuncs.com/temp-folder/2023-02-01%5Cf5a4f8e4-4003-454f-84a1-4603d722ecd6-6a48ae42-04da-4b8a-9fe2-af62b1e4fc99.png"
            />
            <div class="doctor-rgt">
              <p class="rgt-one">
                <span>
                  <span class="name">{{ item.doctorName }}</span>
                  <span class="text"
                    >{{
                      item.departmentName
                        ? item.departmentName.split(",")[0]
                        : ""
                    }}
                    |
                    {{
                      convertDoctorProfessional(item.doctorProfessional)
                    }}</span
                  >
                  <span v-if="item.isDoctorTeam == 1" class="team-box"
                    >团队</span
                  >
                  <span v-if="item.isOnTheJob == 1" class="team-box2"
                    >合作</span
                  >
                </span>
                <span>
                  <span
                    v-if="true"
                    class="cancel-follow"
                    @click.stop="cancelFollowDoctor(item)"
                  >
                    <van-icon name="star" color="#F79C42" /> 取消关注
                  </span>
                  <van-icon v-else name="star" />
                </span>
              </p>
              <p class="doctor-text">{{ item.doctorGood }}</p>
              <p>
                <span
                  class="doctor-tag"
                  v-for="item2 in item.dptTags"
                  :key="item2.id"
                  >{{ item2.roleName }}</span
                >
              </p>
            </div>
          </div>
        </van-list>
      </div>
      <div v-else class="empty-text">暂无专家推荐</div>
    </div>
  </div>
</template>

<script>
import {
  Picker,
  Toast,
  Field,
  Popup,
  Cell,
  Calendar,
  Button,
  Icon,
  Tabs,
  Tab,
  Search,
  Dialog,
  Image,
  List,
} from "vant";

export default {
  name: "orderList",
  components: {
    [Picker.name]: Picker,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [Calendar.name]: Calendar,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Search.name]: Search,
    [Dialog.name]: Dialog,
    [Image.name]: Image,
    [List.name]: List,
  },
  data() {
    return {
      docName: "",
      ListActive: "0",
      doctorList: [],
      //触底加载
      loading: false,
      finished: false,
      error: false,
      pageNum: 1,
      pageSize: 10,
    };
  },
  created() {},
  mounted() {
    this.getDoctorList();
  },
  methods: {
    // 搜索
    onClickButton() {
      this.doctorList = [];
      this.pageNum = 1;
      this.loading = false;
      this.finished = false;
      this.error = false;
      // this.getDoctorList();
    },
    // 列表
    getDoctorList() {
      this.$http
        .request("queryPatFollow", {
          id: this.$route.query.patId,
          name: this.docName,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        })
        .then((res) => {
          // this.doctorList = res;
          this.pageNum++;
          this.doctorList = this.doctorList.concat(res.list);
          this.loading = false;
          if (res.total <= this.doctorList.length) {
            this.finished = true;
          }
        })
        .catch((err) => {});
    },
    // 取消关注
    cancelFollowDoctor(item) {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确认取消关注此医生吗？",
        confirmButtonColor: "#1989fa",
      })
        .then(() => {
          Toast.loading();
          this.$http
            .request("cancelFollowDoctor", {
              id: this.$route.query.patId,
              doctorId: item.id,
            })
            .then((res) => {
              Toast.success("取关成功");
              this.doctorList = [];
              this.pageNum = 1;
              this.loading = false;
              this.finished = false;
              this.error = false;
            })
            .catch((err) => {});
        })
        .catch(() => {
          // on cancel
        });
    },
    // 详情
    toDetail(item) {
	this.$http.request("cardCount",{id:item.id}).then(()=>{}).catch(()=>{})
      this.$router.push("/doctorDetail?docId=" + item.id);
    },
    convertDoctorProfessional(val) {
      if (val == 1) {
        return "主任医师";
      } else if (val == 2) {
        return "副主任医师";
      } else if (val == 3) {
        return "主治医师";
      } else if (val == 4) {
        return "医士";
      } else if (val == 5) {
        return "主任药师";
      } else if (val == 6) {
        return "副主任药师";
      } else if (val == 7) {
        return "主管药师";
      } else if (val == 8) {
        return "药士";
      } else if (val == 9) {
        return "主任护师";
      } else if (val == 10) {
        return "副主任护师";
      } else if (val == 11) {
        return "主管护师";
      } else if (val == 12) {
        return "护士";
      } else {
        return val;
      }
    },
  },
  beforeDestroy() {},
};
</script>
  

<style lang="scss" scoped>
.top-search {
  height: 55px;
}
.doctor-list {
  // background-color: rgb(127, 180, 255);
  .doctor-item {
    display: flex;
    // justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 15px;
    box-sizing: border-box;
    width: 100%;

    .doctor-rgt {
      margin-left: 15px;
      width: calc(100% - 80px);
      .rgt-one {
        display: flex;
        justify-content: space-between;
        .name {
        }
        .text {
          font-size: 13px;
          margin-left: 10px;
          color: #272d3b;
        }
        .cancel-follow {
          color: #9da7b1;
        }
        .team-box {
          color: #278eff;
          background-color: #e8f3fe;
          font-size: 12px;
          padding: 2px 5px;
          border-radius: 2px;
          margin-left: 5px;
        }
        .team-box2 {
          color: #2ac2a8;
          background-color: #d8fcf6;
          font-size: 12px;
          padding: 2px 5px;
          border-radius: 2px;
          margin-left: 5px;
        }
      }

      .doctor-text {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 13px;
        color: #b8c0c7;
        margin: 10px 0;
      }
      .doctor-tag {
        background-color: #fff5e6;
        color: #f79c42;
        font-size: 12px;
        border-radius: 2px;
        margin-right: 10px;
        padding: 0 5px;
      }
    }
  }
  .empty-text {
    color: #9da7b1;
    font-size: 16px;
    text-align: center;
    margin-top: 30px;
  }
}
</style>